import { Col, Form, InputNumber, Row } from "antd";
import React from "react";
import { UnidadMedidaSelect } from "../../../../../generales/unidadesmedida/UnidadMedidaSelect";
import { FormItemHiddenUi } from "../../../../../ui/FormItemHiddenUi";
import { ItemsDevolucionSelect } from "./ItemsDevolucionSelect";

export const SaldosProgramacionTejidoForm = () => {
  const form = Form.useFormInstance();
  const handleChangeItem = (_, option) =>
    form.setFieldsValue({
      item_nombre: option?.label,
      almacen_nombre: option?.almacen_nombre,
      lote: option?.lote,
      numero_lote: option?.numero_lote,
      item: option?.item,
    });

  const handleChangeUnidadMedida = (_, option) =>
    form.setFieldValue("unidadmedida_prefijo", option?.data?.prefijo);

  return (
    <>
      <FormItemHiddenUi name="id" />
      <FormItemHiddenUi name="item_nombre" />
      <FormItemHiddenUi name="almacen_nombre" />
      <FormItemHiddenUi name="lote" />
      <FormItemHiddenUi name="numero_lote" />
      <FormItemHiddenUi name="item" />
      <FormItemHiddenUi name="unidadmedida_prefijo" />
      <FormItemHiddenUi name="estado" />
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <Form.Item
            name="inventario"
            label="Buscar por nombre o sku"
            rules={[{ required: true, message: "Ingrese un item válido" }]}
          >
            <ItemsDevolucionSelect
              placeholder="Buscar item inventariado por nombre o sku"
              onChange={handleChangeItem}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
          <Form.Item
            name="cantidad"
            label="Cantidad"
            rules={[
              {
                required: true,
                type: "number",
                message: "Ingrese un número válido",
              },
            ]}
          >
            <InputNumber
              placeholder="Cantidad"
              style={{ width: "100%" }}
              precision={2}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
          <Form.Item
            name="unidadmedida"
            label="Unidad medida"
            rules={[
              { required: true, message: "Seleccione una unidad de medida" },
            ]}
          >
            <UnidadMedidaSelect
              placeholder="[-Seleccione-]"
              onChange={handleChangeUnidadMedida}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
