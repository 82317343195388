import React from "react";
import { Grid, Image } from "antd";
import LogoAleman from "../../../static/img/LogoAleman.jpg";
const { useBreakpoint } = Grid;
export const LogoMenu = (LogoBreackPoint) => {
  const screens = useBreakpoint();
  //   console.log(screens);
  const prueba = Object.entries(screens).filter((screen) => !!screen[1]);
  //   console.log(prueba);
  return (
    <div className="logo-vertical">
      <Image src={LogoAleman} style={{ height: "50px" }} preview={false} />
    </div>
  );
};
