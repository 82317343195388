import { FilePdfOutlined, ZoomInOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import {
  get_pdf_movimiento_traslado,
  movimientoSetActive,
  showModalDetalle,
} from "../../../actions/inventario";
import { open_pdf_file } from "../../../helpers/utils";
import { TableUi } from "../../ui/TableUi";
const columns_table = (acciones) => {
  const { onHandleDetalleMovimiento, onHandlePDFMovimiento } = acciones;
  return [
    {
      title: "#",
      dataIndex: "nro",
      key: "nro",
      align: "center",
      render: (text, record, index) => <>{index + 1}</>,
    },
    {
      title: "Almacén Inicial",
      dataIndex: "almacenorigen_nombre",
      key: "almacenorigen_nombre",
    },
    {
      title: "Almacén Destino",
      dataIndex: "almacendestino_nombre",
      key: "almacendestino_nombre",
    },
    {
      title: "Comprobante",
      dataIndex: "comprobante_movimiento",
      key: "comprobante_movimiento",
      align: "center",
    },
    {
      title: "Fecha emisión",
      dataIndex: "fechaemision",
      key: "fechaemision",
      align: "center",
    },
    {
      title: "Detalle",
      dataIndex: "detalle",
      key: "detalle",
      align: "center",
      render: (text, record) => (
        <Button
          size="small"
          onClick={() => {
            onHandleDetalleMovimiento(record);
          }}
        >
          <ZoomInOutlined style={{ color: "#1890ff" }} />
        </Button>
      ),
    },
    {
      title: "Observación",
      dataIndex: "observacion",
      key: "observacion",
    },
    {
      title: "Acciones",
      dataIndex: "acciones",
      key: "acciones",
      render: (text, record) => (
        <Button
          type="primary"
          danger
          size="small"
          onClick={() => {
            onHandlePDFMovimiento(record);
          }}
        >
          <FilePdfOutlined />
          PDF
        </Button>
      ),
    },
  ];
};
export const MovimientoTrasladoTable = ({ movimientos, loading }) => {
  const dispatch = useDispatch();
  const onHandleDetalleMovimiento = (data) => {
    dispatch(movimientoSetActive(data));
    dispatch(showModalDetalle(true));
  };
  const onHandlePDFMovimiento = async (comprobantemovimiento) => {
    const data = await get_pdf_movimiento_traslado(comprobantemovimiento?.id);
    let name_file = comprobantemovimiento?.comprobante_movimiento + ".pdf";
    open_pdf_file(data, name_file);
  };
  return (
    <TableUi
      columns={columns_table({
        onHandleDetalleMovimiento,
        onHandlePDFMovimiento,
      })}
      rowKey="id"
      data={movimientos}
      loading={loading}
      size="small"
    />
  );
};
