import React, { useEffect, useState } from "react";
import { get_unidadesmedida } from "../../../actions/producto";
import { SelectUi } from "../../ui/SelectUi";

export const UnidadMedidaSelect = ({ ...props }) => {
  const [datos, setDatos] = useState([]);
  const OnLoad = async () => {
    const data = await get_unidadesmedida();
    const newData = (data || []).map((item) => ({
      value: item?.id,
      label: item?.nombre,
      data: { ...item },
    }));
    setDatos(newData);
  };
  useEffect(() => {
    OnLoad();
  }, []);

  return <SelectUi data={datos} {...props}></SelectUi>;
};
