import React from "react";
import { TipoComprobanteRadio } from "../../reusable/TipoComprobanteRadio";
import { Col, Row, Form } from "antd";
import { SerieComprontesSelect } from "../../reusable/SerieComprontesSelect";

export const DatosComprobante = () => {
  const form = Form.useFormInstance();
  const terminalcajero = Form.useWatch("terminalcajero", form);
  const tipocomprobante = Form.useWatch("tipocomprobante", form);
  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col sm={24} md={16} lg={16} xl={16}>
          <Form.Item
            name="tipocomprobante"
            rules={[
              {
                required: true,
                message: "El tipo de comprobante es obligatorio",
              },
            ]}
          >
            <TipoComprobanteRadio
              terminalcajero={terminalcajero}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col sm={24} md={8} lg={8} xl={6}>
          <Form.Item
            name="seriecomprobante"
            style={{ marginBottom: 0 }}
            rules={[
              {
                required: true,
                message: "La serie del comprobante es obligatorio",
              },
            ]}
          >
            <SerieComprontesSelect
              terminalcajero={terminalcajero}
              tipocomprobante={tipocomprobante}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
