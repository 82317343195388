import React, { useEffect } from "react";
import { PageHeaderUi } from "../../../../ui/PageHeaderUi";
import { ButtonUi } from "../../../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../../../types/typesConstants";
import { Col, Row, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  get_excel_programacionestintoreria,
  programacionTintoreriaStartLoading,
  showFormProgramacionTintoreria,
} from "../../../../../actions/produccion";
import { ProgramacionTintoreriaTable } from "./ProgramacionTintoreriaTable";
import { ProgramacionTintoreriaScreen } from "./ProgramacionTintoreriaScreen";
import { ConsolidadoTelaScreen } from "../../consolidado/ConsolidadoTelaScreen";
import { ProgramacionTintoreriaSearchTable } from "./ProgramacionTintoreriaSearchTable";
import { FileExcelOutlined } from "@ant-design/icons";
import { messageError } from "../../../../../helpers/messageConfig";
import { open_file } from "../../../../../helpers/utils";

const ProgramacionTintoteriaListScreen = () => {
  const dispatch = useDispatch();
  const { programacionesTintoreria, loading } = useSelector(
    (state) => state.produccion
  );
  const handleNuevo = () => {
    dispatch(showFormProgramacionTintoreria());
  };
  const handleReporteExcel = async () => {
    if (programacionesTintoreria?.length > 0) {
      const keys_programaciones = programacionesTintoreria
        ?.map((item) => item?.id)
        .join(",");
      const file = await get_excel_programacionestintoreria(
        keys_programaciones
      );
      let name_file = "ProgramacionesTintoreria.xlsx";
      open_file(file, name_file, file?.type);
    } else messageError("No existe información para imprimir");
  };
  return (
    <PageHeaderUi
      title="Programación de Tintoreria"
      subTitle="Listado"
      extra={[
        <Space key="opts" wrap>
          <ButtonUi
            key="rptexcel"
            typeUi={TipoButtonConstants.CustomButton}
            onClick={handleReporteExcel}
            icon={<FileExcelOutlined />}
            text="Reporte"
            type="success"
          />
          <ButtonUi
            key="nuevo"
            typeUi={TipoButtonConstants.Nuevo}
            onClick={handleNuevo}
          />
          ,
        </Space>,
      ]}
    >
      <div style={{ overflow: "hidden" }}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={24}>
            <ProgramacionTintoreriaSearchTable />
          </Col>
        </Row>
        <Row>
          <Col sm={32} md={32} xl={24}>
            <ProgramacionTintoreriaTable
              data={programacionesTintoreria}
              loading={loading}
            />
          </Col>
        </Row>
      </div>
    </PageHeaderUi>
  );
};

export const ProgramacionTintoreriaList = () => {
  const { visibleFormProgramacionTintoreria, visibleFormConsolidadoTela } =
    useSelector((state) => state.produccion);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(programacionTintoreriaStartLoading());
  }, []);
  if (visibleFormProgramacionTintoreria) {
    return <ProgramacionTintoreriaScreen />;
  } else if (visibleFormConsolidadoTela) {
    return <ConsolidadoTelaScreen />;
  } else {
    return <ProgramacionTintoteriaListScreen />;
  }
};
export default ProgramacionTintoreriaList;
