import React from "react";
import { PageHeaderUi } from "../../ui/PageHeaderUi";
import { ButtonUi } from "../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../types/typesConstants";
import { Col, Row } from "antd";
import { MovimientoSearchForm } from "./MovimientoSearchForm";
import { MovimientosTable } from "./MovimientosTable";
import { useDispatch, useSelector } from "react-redux";
import { MovimientoEntradaScreen } from "./entrada/MovimientoEntradaScreen";
import {
  showFormInputMovements,
  showFormOutputMovements,
} from "../../../actions/inventario";
import { MovimientoSalidaScreen } from "./salida/MovimientoSalidaScreen";

export const MovimientosListScreen = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.ui);
  const { comprobantesMovimiento } = useSelector((state) => state.inventario);

  const handleNuevoIngreso = () => {
    dispatch(showFormInputMovements());
  };
  const handleNuevaSalida = () => {
    dispatch(showFormOutputMovements());
  };
  return (
    <PageHeaderUi
      title="Movimientos"
      subTitle="Listado"
      extra={[
        <ButtonUi
          key="ingreso"
          typeUi={TipoButtonConstants.Agregar}
          text="Ingreso"
          onClick={handleNuevoIngreso}
        />,
        <ButtonUi
          key="salida"
          typeUi={TipoButtonConstants.Disminuir}
          text="Salida"
          onClick={handleNuevaSalida}
        />,
      ]}
    >
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <MovimientoSearchForm />
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <MovimientosTable data={comprobantesMovimiento} loading={loading} />
        </Col>
      </Row>
    </PageHeaderUi>
  );
};

export const MovimientosList = () => {
  const { visibleFormInput, visibleFormOutput } = useSelector(
    (state) => state.inventario
  );
  if (visibleFormInput) {
    return <MovimientoEntradaScreen />;
  } else if (visibleFormOutput) {
    return <MovimientoSalidaScreen />;
  } else {
    return <MovimientosListScreen />;
  }
};
