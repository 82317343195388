import { CloseOutlined } from "@ant-design/icons";
import { Button, Modal, Table } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { showModalDetalle } from "../../../actions/inventario";
const columns = [
  {
    title: "#",
    dataIndex: "nro",
    key: "nro",
    render: (text, record, index) => <>{index + 1}</>,
  },
  {
    title: "Item",
    dataIndex: "item_nombre",
    key: "item_nombre",
  },
  {
    title: "Cantidad",
    dataIndex: "cantidad",
    key: "cantidad",
  },
  {
    title: "U.Medida",
    dataIndex: "unidadmedida_nombre",
    key: "unidadmedida_nombre",
  },
];
export const MovimientoTrasladoDetalleModal = () => {
  const dispatch = useDispatch();
  const { movimientoActive, visibleModalDetalleMovimiento } = useSelector(
    (state) => state.inventario
  );

  const handleCancel = () => {
    dispatch(showModalDetalle(false));
  };
  return (
    <Modal
      title="Detalle - Items"
      centered
      maskClosable={false}
      destroyOnClose
      open={visibleModalDetalleMovimiento}
      onCancel={handleCancel}
      width="50%"
      footer={[
        <Button
          key="back"
          danger
          icon={<CloseOutlined />}
          onClick={handleCancel}
        >
          Cancel
        </Button>,
      ]}
    >
      <Table
        columns={columns}
        dataSource={movimientoActive?.detallescomprobantemovimiento}
        rowKey="id"
        size="small"
      />
    </Modal>
  );
};
