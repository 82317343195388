import { Form } from "antd";
import React, { useState } from "react";
import { FormUi } from "../../../../ui/FormUi";
import { SaldosProgramacionTejidoForm } from "./SaldosProgramacionTejidoForm";
import { useDispatch, useSelector } from "react-redux";
import { SaldosProgramacionTejidoModal } from "./modal/SaldosProgramacionTejidoModal";
import dayjs from "dayjs";
import {
  programacionTejidoStartUpdate,
  saldoProgramacionTejidoClearActive,
} from "../../../../../actions/produccion";
import { EstadoProcesoConstants } from "../../../../../types/typesConstants";
const initialValues = (data = {}) => ({
  id: data?.id || null,
  saldototal: data?.saldototal || 0,
  saldosprogramaciontejido: data?.saldosprogramaciontejido || [],
});

export const SaldosProgramacionTejidoScreen = ({ handleCloseForm }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { activeProgramacionTejido, activeSaldoProgramacionTejido } =
    useSelector((state) => state.produccion);
  const [openSaldosModal, setOpenSaldosModal] = useState(false);
  const handleOpenSaldosModal = () => setOpenSaldosModal(true);
  const handleCloseSaldosModal = () => setOpenSaldosModal(false);
  const handleSave = (values) => {
    const saldosprogramaciontejido = values?.saldosprogramaciontejido?.map(
      (item) => ({
        ...item,
        estado: !item?.estado ? EstadoProcesoConstants.PROCESO : item?.estado,
      })
    );
    values = {
      ...activeProgramacionTejido,
      saldosprogramaciontejido,
      saldototal: values?.saldototal,
    };
    dispatch(programacionTejidoStartUpdate(values));
    dispatch(saldoProgramacionTejidoClearActive());
    handleCloseForm();
  };
  return (
    <Form.Provider
      onFormFinish={(name, { values, forms }) => {
        const { FormSaldosProgramacionTejido } = forms;
        let saldosprogramaciontejido =
          FormSaldosProgramacionTejido.getFieldValue(
            "saldosprogramaciontejido"
          ) || [];
        // console.log(values);
        if (name === "FormModalSaldoProgramacionTejido") {
          if (activeSaldoProgramacionTejido)
            saldosprogramaciontejido = saldosprogramaciontejido.map((e) =>
              e.id === values?.id ? values : e
            );
          else
            saldosprogramaciontejido = [
              { ...values, id: dayjs().valueOf() },
              ...saldosprogramaciontejido,
            ];
          form.setFieldValue(
            "saldosprogramaciontejido",
            saldosprogramaciontejido
          );
          handleCloseSaldosModal();
        }
      }}
    >
      <FormUi
        name="FormSaldosProgramacionTejido"
        layout="vertical"
        autoComplete="off"
        initialValues={initialValues(activeProgramacionTejido)}
        form={form}
        handleSubmit={handleSave}
      >
        <SaldosProgramacionTejidoForm
          handleOpenSaldosModal={handleOpenSaldosModal}
          handleCloseForm={handleCloseForm}
        />
      </FormUi>
      <SaldosProgramacionTejidoModal
        open={openSaldosModal}
        handleCancelModal={handleCloseSaldosModal}
      />
    </Form.Provider>
  );
};
