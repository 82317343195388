import { types } from "../types/typesInventario";

const initialState = {
  inventariosProducto: [],
  inventarioActive: null,
  visibleModalIngreso: false,
  visibleModalEgreso: false,
  visibleModalDetalleMovimiento: false,
  visibleModalRemove: false,
  movimientoActive: null,
  movimientosInventario: [],
  visibleFormInput: false,
  visibleFormOutput: false,
  visibleFormRemove: false,
  visibleFormTransfer: false,
  quantitySelectedSeries: 0,
  comprobantesMovimiento: [],
  comprobantesMovimientoTraslado: [],
  activeComprobanteMovimiento: null,
  activeDetalleComprobanteMovimiento: null,
  loading: false,
};
export const inventarioReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.productosInventarioLoaded:
      return {
        ...state,
        inventariosProducto: action.payload,
      };
    case types.inventarioInserted:
      return {
        ...state,
        inventariosProducto: [action.payload, ...state.inventariosProducto],
      };
    case types.inventarioUpdated:
      return {
        ...state,
        inventariosProducto: state.inventariosProducto.map((e) =>
          e.id === action.payload.id ? action.payload : e
        ),
      };
    case types.inventariosInserted:
      return {
        ...state,
        inventariosProducto: [...action.payload, ...state.inventariosProducto],
      };
    case types.inventariosUpdated:
      return {
        ...state,
        inventariosProducto: state.inventariosProducto.map((e) => {
          const index = action.payload.findIndex((x) => x.id === e.id);
          return index > -1 ? action.payload[index] : e;
        }),
      };
    case types.inventarioSetActive:
      return {
        ...state,
        inventarioActive: action.payload,
      };
    case types.inventarioClearActive:
      return {
        ...state,
        inventarioActive: null,
      };
    case types.inventarioShowModalEgreso:
      return {
        ...state,
        visibleModalEgreso: action.payload,
      };
    case types.inventarioShowModalIngreso:
      return {
        ...state,
        visibleModalIngreso: action.payload,
      };
    case types.inventarioShowModalRemover:
      return {
        ...state,
        visibleModalRemove: action.payload,
      };
    case types.inventarioShowFormInput:
      return {
        ...state,
        visibleFormInput: action.payload,
      };
    case types.inventarioShowFormOutput:
      return {
        ...state,
        visibleFormOutput: action.payload,
      };
    case types.inventarioShowFormTransfer:
      return {
        ...state,
        visibleFormTransfer: action.payload,
      };
    case types.inventarioShowFormRemove:
      return {
        ...state,
        visibleFormRemove: action.payload,
      };
    case types.movimientoSetActive:
      return {
        ...state,
        movimientoActive: action.payload,
      };
    case types.movimientoClearActive:
      return {
        ...state,
        movimientoActive: null,
      };
    case types.movimientoShowModalDetalle:
      return {
        ...state,
        visibleModalDetalleMovimiento: action.payload,
      };
    case types.inventarioSetQuantitySelectSeries:
      return {
        ...state,
        quantitySelectedSeries: action.payload,
      };
    case types.comprobantesMovimientoLoaded:
      return {
        ...state,
        comprobantesMovimiento: action.payload,
      };
    case types.comprobanteMovimientoInserted:
      return {
        ...state,
        comprobantesMovimiento: [
          action.payload,
          ...state.comprobantesMovimiento,
        ],
      };
    case types.comprobanteMovimientoUpdated:
      return {
        ...state,
        comprobantesMovimiento: state.comprobantesMovimiento.map((e) =>
          e.id === action.payload.id ? action.payload : e
        ),
      };
    case types.comprobanteMovimientoSetActive:
      return {
        ...state,
        activeComprobanteMovimiento: action.payload,
      };
    case types.comprobanteMovimientoClearActive:
      return {
        ...state,
        activeComprobanteMovimiento: null,
      };
    case types.detalleComprobanteMovimientoSetActive:
      return {
        ...state,
        activeDetalleComprobanteMovimiento: action.payload,
      };
    case types.detalleComprobanteMovimientoClearActive:
      return {
        ...state,
        activeDetalleComprobanteMovimiento: null,
      };
    case types.comprobantesMovimientoTrasladoLoaded:
      return {
        ...state,
        comprobantesMovimientoTraslado: action.payload,
      };
    case types.comprobanteMovimientoTrasladoInserted:
      return {
        ...state,
        comprobantesMovimientoTraslado: [
          action.payload,
          ...state.comprobantesMovimientoTraslado,
        ],
      };
    case types.comprobanteMovimientoStatusLoading:
      return { ...state, loading: action.payload };
    case types.comprobantesMovimientoInitialize:
      return initialState;
    default:
      return state;
  }
};
