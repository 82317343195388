import { Col, Form, Row, Transfer } from "antd";
import React, { useEffect, useState } from "react";
import { get_sucursales } from "../../../actions/listaprecio";

const filterOption = (inputValue, option) =>
  option.description.indexOf(inputValue) > -1;

export const ListaPrecioSucursalDetalle = () => {
  const form = Form.useFormInstance();
  const [sucursales, setSucursales] = useState([]);
  const [targetKeys, setTargetKeys] = useState([]);
  useEffect(() => {
    const OnLoad = async () => {
      const data = await get_sucursales();
      const options = data?.map((e) => ({
        key: e.value,
        title: e.label,
        description: e.label,
      }));
      setSucursales(options);
    };
    OnLoad();
    const keys = [];
    const { listapreciosucursal } = form.getFieldsValue();
    listapreciosucursal?.forEach((e) => {
      keys.push(e.local);
    });
    setTargetKeys(keys);
    return () => {
      setSucursales([]);
    };
  }, []);

  const handleChange = (newTargetKeys) => {
    setTargetKeys(newTargetKeys);
  };
  return (
    <>
      <Row>
        <Col xs="24" sm="24" md="24" lg="12" xl="12">
          <Form.Item
            name="listapreciosucursal"
            rules={[
              { required: true, message: "Seleccione al menos una sucursal" },
            ]}
          >
            <Transfer
              dataSource={sucursales}
              showSearch
              filterOption={filterOption}
              targetKeys={targetKeys}
              onChange={handleChange}
              listStyle={{
                width: 400,
              }}
              pagination
              //   onSearch={handleSearch}
              render={(item) => item.title}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
