import React, { useEffect, useState } from "react";
import { get_mediospago } from "../../../actions/comprobante";
import { SelectUi } from "../../ui/SelectUi";
import { Form } from "antd";

export const MedioPagoSelect = ({ nameProps, ...props }) => {
  const [mediospagodata, setMediospagodata] = useState([]);
  const form = Form.useFormInstance();
  useEffect(() => {
    const OnLoadMediosPago = async () => {
      const datos = await get_mediospago();
      const tiposmediopago = Object.groupBy(
        datos,
        ({ tipomediopago_nombre }) => tipomediopago_nombre
      );
      let opts = [];
      for (const key in tiposmediopago) {
        opts = [
          ...opts,
          {
            title: key,
            label: <span>{key}</span>,
            options: [...tiposmediopago[key]],
          },
        ];
      }
      setMediospagodata(opts);
      const OptionDefault =
        datos.filter((x) => x.pordefecto === true)?.[0] || datos?.[0];
      form.setFieldValue(nameProps, OptionDefault?.value);
    };
    OnLoadMediosPago();
  }, []);

  return <SelectUi data={mediospagodata} {...props} />;
};
