import React, { useEffect, useState } from "react";
import { get_puntosventa_by_empleado } from "../../../../actions/local";
import { SelectUi } from "../../../ui/SelectUi";

export const PuntoVentaEmpleadoSelect = ({ empleado, ...props }) => {
  const [puntosVenta, setPuntosVenta] = useState([]);

  useEffect(() => {
    const OnLoadAlmacenes = async (empleado) => {
      const datos = await get_puntosventa_by_empleado(empleado);
      setPuntosVenta(datos);
    };
    OnLoadAlmacenes(empleado);
  }, [empleado]);

  return <SelectUi data={puntosVenta} {...props} />;
};
