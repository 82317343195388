import React from "react";
import { ModalUi } from "../../ui/ModalUi";
import { ButtonUi } from "../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../types/typesConstants";
import { ListaPreciosTable } from "./ListaPreciosTable";

export const ListaPreciosShowModal = ({ listaprecios, open, onCancel }) => {
  return (
    <ModalUi
      title="Lista de precios"
      centered
      maskClosable={false}
      destroyOnClose
      open={open}
      onCancel={onCancel}
      footer={[
        <ButtonUi
          key="back"
          typeUi={TipoButtonConstants.Cancelar}
          onClick={onCancel}
        />,
      ]}
    >
      <ListaPreciosTable listaprecios={listaprecios} />
    </ModalUi>
  );
};
