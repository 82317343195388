import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { AperturaCajaDrawer } from "./aperturacaja/AperturaCajaDrawer";
import { CajasTable } from "./CajasTable";
import { PageHeaderUi } from "../../ui/PageHeaderUi";
import { ButtonUi } from "../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../types/typesConstants";
import { CajasSearchFormTable } from "./CajasSearchFormTable";
import { useDispatch, useSelector } from "react-redux";
import { cajasStartLoad } from "../../../actions/caja";

export const CajasList = () => {
  const [open, setOpen] = useState(false);
  const { activeEmpleado } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const handleOpenDrawer = () => setOpen(true);
  const handleCloseDrawer = () => setOpen(false);
  useEffect(() => {
    dispatch(cajasStartLoad(activeEmpleado?.id));
  }, []);
  return (
    <>
      <PageHeaderUi
        title="Cajas"
        subTitle="Listado"
        extra={[
          <ButtonUi
            key="nuevo"
            typeUi={TipoButtonConstants.Nuevo}
            onClick={handleOpenDrawer}
          />,
        ]}
      >
        <div style={{ overflow: "hidden" }}>
          <Row>
            <Col span={24}>
              <CajasSearchFormTable />
            </Col>
          </Row>
          <Row>
            <Col sm={32} md={32} xl={24}>
              <CajasTable openDrawer={handleOpenDrawer} />
            </Col>
          </Row>
        </div>
      </PageHeaderUi>
      <AperturaCajaDrawer open={open} handleCloseDrawer={handleCloseDrawer} />
    </>
  );
};
export default CajasList;
