import { Col, DatePicker, Form, Input, InputNumber, Row } from "antd";
import React, { useEffect, useState } from "react";
import { SwitchUi } from "../../ui/SwitchUi";
import { CardUi } from "../../ui/CardUi";
import { ListaPrecioSucursalDetalle } from "./ListaPrecioSucursalDetalle";
import { MinusOutlined, PlusOutlined, ShopOutlined } from "@ant-design/icons";
import { FormItemHiddenUi } from "../../ui/FormItemHiddenUi";
import {
  DateFormats,
  TipoCalculoListaPrecio,
} from "../../../types/typesConstants";
import { TipoCalculoSelect } from "./reusable/TipoCalculoSelect";

export const ListaPrecioForm = () => {
  const form = Form.useFormInstance();
  const [valueCalculo, setValueCalculo] = useState(null);
  const handleChangeTipoCalculo = (value) => {
    setValueCalculo(value);
    form.setFieldsValue({
      valorporcentaje: null,
      efectocalculoporcentaje: true,
    });
  };
  useEffect(() => {
    setValueCalculo(form.getFieldValue("tipocalculo"));
  }, []);

  return (
    <>
      <FormItemHiddenUi name="id" />
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name="nombre"
            label="Nombre"
            rules={[{ required: true, message: "El nombre es obligatorio" }]}
          >
            <Input placeholder="Ingrese nombre" maxLength={100} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item name="state" label="¿Activo?" valuePropName="checked">
            <SwitchUi />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={8} lg={6} xl={4}>
          <Form.Item name="tipocalculo" label="Tipo calculo">
            <TipoCalculoSelect
              placeholder="[-Seleccione-]"
              onChange={handleChangeTipoCalculo}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={6} lg={4} xl={3}>
          <Form.Item
            name="valorporcentaje"
            label="Valor porcentaje"
            hidden={valueCalculo !== TipoCalculoListaPrecio.PORCENTAJE}
          >
            <InputNumber
              precision={2}
              style={{ width: "100%" }}
              addonAfter="%"
              min={0}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={6} lg={4} xl={3}>
          <Form.Item
            name="efectocalculoporcentaje"
            label="Efecto calculo"
            hidden={valueCalculo !== TipoCalculoListaPrecio.PORCENTAJE}
          >
            <SwitchUi
              checkedChildren={
                <>
                  <MinusOutlined /> Descuenta
                </>
              }
              unCheckedChildren={
                <>
                  <PlusOutlined /> Aumenta
                </>
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={8} lg={6} xl={4}>
          <Form.Item name="fechahorainicio" label="Fecha hora inicio">
            <DatePicker format={DateFormats.DatetimeFrontEnd} showTime />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8} lg={6} xl={4}>
          <Form.Item name="fechahorafin" label="Fecha hora fin">
            <DatePicker format={DateFormats.DatetimeFrontEnd} showTime />
          </Form.Item>
        </Col>
      </Row>
      <CardUi
        title={
          <>
            <ShopOutlined /> Sucursales
          </>
        }
        size="small"
        type="inner"
      >
        <ListaPrecioSucursalDetalle />
      </CardUi>
      {/* <ListaPrecioTabForm /> */}
    </>
  );
};
