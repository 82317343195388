import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uiShowForm } from "../../actions/ui";
import {
  productoClearActiveProducto,
  productoStartAddNew,
  productoStartUpdate,
} from "../../actions/producto";
import { ProductoFormScreen } from "./ProductoFormScreen";
import { FormUi } from "../ui/FormUi";
import { Form } from "antd";

const initialValues = (data = {}) => ({
  id: data?.id || 0,
  nombre: data?.nombre || null,
  descripcion: data?.descripcion || null,
  precioventa: parseFloat(data?.precioventa || 0),
  costo: parseFloat(data?.costo || 0),
  moneda: data?.moneda || null,
  stockminimo: parseFloat(data?.stockminimo || 0),
  sku: data?.sku || null,
  unidadmedida: data?.unidadmedida || null,
  categoriaitem: data?.categoriaitem || null,
  subcategoriaitem: data?.subcategoriaitem || null,
  sevende: data?.sevende || false,
  esfabricado: data?.esfabricado || false,
  rutaproduccion: data?.rutaproduccion || null,
  esinventariado: data?.esinventariado || false,
  tipoafectacionigv: data?.tipoafectacionigv || null,
  tipoitem: data?.tipoitem || null,
  imagen: data?.imagen || null,
  itemlistaprecios: data?.itemlistaprecios || [],
  almaceninicial: data?.almaceninicial || null,
  stockinicial: parseFloat(data?.stockinicial || 0),
  manejalotes: data?.manejalotes || false,
  manejaseries: data?.manejaseries || false,
  empresa: data?.empresa || null,
});

export const ProductoScreen = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { activeProducto } = useSelector((state) => state.producto);
  const { activeEmpleado } = useSelector((state) => state.auth);
  const [tabKeyError, setTabKeyError] = useState(null);
  const handleSubmit = (values) => {
    setTabKeyError(null);
    let valuesProducto = {
      ...values,
      imagen: values?.imagen || "",
      empresa: activeEmpleado?.empresa?.id,
    };
    if (activeProducto) {
      dispatch(productoStartUpdate(valuesProducto));
    } else {
      dispatch(productoStartAddNew(valuesProducto));
    }
    dispatch(uiShowForm(false));
    dispatch(productoClearActiveProducto());
  };
  const handleFailed = ({ errorFields }) => {
    if (errorFields.length > 0) {
      const tabActive = errorFields?.[0]?.name?.includes("itemlistaprecios")
        ? "listaprecios"
        : "general";
      setTabKeyError(tabActive);
    } else setTabKeyError(null);
  };
  return (
    <>
      <FormUi
        form={form}
        initialValues={initialValues(activeProducto)}
        handleSubmit={handleSubmit}
        layout="vertical"
        autoComplete="off"
        onFinishFailed={handleFailed}
      >
        <ProductoFormScreen tabKeyError={tabKeyError} />
      </FormUi>
    </>
  );
};
