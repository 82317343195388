import React from "react";
import { SearchFormUi } from "../../ui/SearchFormUi";
import { Col, DatePicker, Form, Input, Row } from "antd";
import { AlmacenesEmpleadoSelect } from "../reusable/AlmacenesEmpleadoSelect";
import { useDispatch, useSelector } from "react-redux";
import { ButtonUi } from "../../ui/ButtonUi";
import {
  DateFormats,
  FiltersSearch_Types,
  TipoButtonConstants,
} from "../../../types/typesConstants";
import { comprobanteMovimientoStartLoading } from "../../../actions/inventario";
import { SelectUi } from "../../ui/SelectUi";
import { TipoComprobanteMovimientoSelect } from "./TipoComprobanteMovimientoSelect";
import dayjs from "dayjs";
import { parseDateFormatBackEnd } from "../../../helpers/utils";

const initialValues = {
  tipofiltro: FiltersSearch_Types.FECHAEMISION,
  tipocomprobantemovimiento: null,
  fechaemision: [dayjs(), dayjs()],
  almacen: null,
  comprobante: null,
};
const _tipofiltro = [
  {
    value: FiltersSearch_Types.TIPOCOMPROBANTE,
    label: "TIPO COMPROBANTE",
  },
  {
    value: FiltersSearch_Types.FECHAEMISION,
    label: "FECHA EMISIÓN",
  },
  {
    value: FiltersSearch_Types.ALMACEN,
    label: "ALMACEN",
  },
  {
    value: FiltersSearch_Types.COMPROBANTE,
    label: "COMPROBANTE",
  },
];
export const MovimientoSearchForm = () => {
  const [form] = Form.useForm();
  const tipofiltro = Form.useWatch("tipofiltro", form);
  const dispatch = useDispatch();
  const { activeEmpleado } = useSelector((state) => state.auth);
  const handleSubmit = (values) => {
    let query = null;
    if (tipofiltro === FiltersSearch_Types.TIPOCOMPROBANTE)
      query = values?.tipocomprobantemovimiento;
    else if (tipofiltro === FiltersSearch_Types.FECHAEMISION)
      query = `${parseDateFormatBackEnd(
        values?.fechaemision?.[0]
      )}|${parseDateFormatBackEnd(values?.fechaemision?.[1])}`;
    else if (tipofiltro === FiltersSearch_Types.ALMACEN)
      query = values?.almacen;
    else if (tipofiltro === FiltersSearch_Types.COMPROBANTE)
      query = values?.comprobante;

    dispatch(comprobanteMovimientoStartLoading(values?.tipofiltro, query));
  };
  return (
    <SearchFormUi
      name="FormSearchMovimientos"
      form={form}
      autoComplete="off"
      initialValues={initialValues}
      handleSubmit={handleSubmit}
    >
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={16} md={12} lg={8} xl={6}>
          <Form.Item name="tipofiltro" label="Buscar por">
            <SelectUi data={_tipofiltro} />
          </Form.Item>
        </Col>
        {tipofiltro === FiltersSearch_Types.TIPOCOMPROBANTE && (
          <Col xs={24} sm={16} md={12} lg={8} xl={8}>
            <Form.Item
              name="tipocomprobantemovimiento"
              label="Tipo comprobante"
            >
              <TipoComprobanteMovimientoSelect
                placeholder="[-TODOS-]"
                allowClear
              />
            </Form.Item>
          </Col>
        )}
        {tipofiltro === FiltersSearch_Types.ALMACEN && (
          <Col xs={24} sm={16} md={12} lg={8} xl={8}>
            <Form.Item name="almacen" label="Almacén">
              <AlmacenesEmpleadoSelect
                placeholder="[-TODOS-]"
                allowClear
                empleado={activeEmpleado?.id}
              />
            </Form.Item>
          </Col>
        )}
        {tipofiltro === FiltersSearch_Types.FECHAEMISION && (
          <Col xs={24} sm={16} md={12} lg={8} xl={8}>
            <Form.Item
              name="fechaemision"
              label="Fecha emisión"
              rules={[
                {
                  required: FiltersSearch_Types.FECHAEMISION,
                  message: "La fecha de emisión es obligatorio",
                },
              ]}
            >
              <DatePicker.RangePicker format={DateFormats.frontend} />
            </Form.Item>
          </Col>
        )}
        {tipofiltro === FiltersSearch_Types.COMPROBANTE && (
          <Col xs={24} sm={16} md={12} lg={8} xl={8}>
            <Form.Item
              name="comprobante"
              label="Comprobante"
              rules={[
                {
                  required: tipofiltro === FiltersSearch_Types.COMPROBANTE,
                  message: "El comprobante es obligatorio",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        )}
        <Col xs={24} sm={8} md={12} lg={6} xl={6}>
          <Form.Item>
            <ButtonUi typeUi={TipoButtonConstants.Filtrar} htmlType="submit" />
          </Form.Item>
        </Col>
      </Row>
    </SearchFormUi>
  );
};
