import React from "react";
import { ModalUi } from "../../../../../ui/ModalUi";
import { TipoButtonConstants } from "../../../../../../types/typesConstants";
import { ButtonUi } from "../../../../../ui/ButtonUi";
import { ProcesarSaldosProgramacionTejidoForm } from "./ProcesarSaldosProgramacionTejidoForm";
import { FormUi } from "../../../../../ui/FormUi";
import { Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { programacionTejidoSaldosStartProcess } from "../../../../../../actions/produccion";
const initialValues = {
  almacen: null,
  tipomovimiento: null,
};
export const ProcesarSaldosProgramacionTejidoModal = ({
  open,
  handleCloseModal,
  handleCloseForm,
  saldosprogramaciontejido,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { activeEmpleado } = useSelector((state) => state.auth);
  const { activeProgramacionTejido } = useSelector((state) => state.produccion);
  const handleSubmit = ({ tipomovimiento, almacen }) => {
    const detallescomprobantemovimiento = saldosprogramaciontejido?.map(
      ({ item, cantidad, unidadmedida, numero_lote }) => {
        let lotes = [];
        if (numero_lote) lotes = [{ numero_lote, cantidad }];
        return { item, cantidad, unidadmedida, lotes };
      }
    );
    const values = {
      programaciontejido: activeProgramacionTejido?.id,
      tipomovimiento,
      almacenorigen: almacen,
      almacendestino: almacen,
      empresa: activeEmpleado?.empresa?.id,
      detallescomprobantemovimiento,
    };
    dispatch(programacionTejidoSaldosStartProcess(values));
    handleCloseModal();
    handleCloseForm();
  };
  return (
    <ModalUi
      title="Saldos programacion tejido"
      centered
      maskClosable={false}
      destroyOnClose
      open={open}
      onCancel={handleCloseModal}
      width={{
        xs: "90%",
        sm: "80%",
        md: "70%",
        lg: "60%",
        xl: "50%",
        xxl: "40%",
      }}
      footer={[
        <ButtonUi
          key="back"
          typeUi={TipoButtonConstants.Cancelar}
          onClick={handleCloseModal}
        />,
        <ButtonUi
          key="submit"
          htmlType="submit"
          form="FormModalProcesarSaldoProgramacionTejido"
          typeUi={TipoButtonConstants.Guardar}
        />,
      ]}
      forceRender
    >
      <FormUi
        name="FormModalProcesarSaldoProgramacionTejido"
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={initialValues}
        handleSubmit={handleSubmit}
      >
        <ProcesarSaldosProgramacionTejidoForm
          saldosprogramaciontejido={saldosprogramaciontejido}
        />
      </FormUi>
    </ModalUi>
  );
};
