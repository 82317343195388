import {
  DeleteOutlined,
  DollarOutlined,
  DownOutlined,
  EditOutlined,
  FileExcelOutlined,
  RightSquareOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Space } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  arqueoCajaSetActive,
  cajaAbiertaSetActive,
  cajaSetActive,
  cajaStartDelete,
  file_download_excel_arqueocaja,
} from "../../../actions/caja";
import { showDeleteConfirm } from "../../../helpers/confirmModal";
import { TableUi } from "../../ui/TableUi";
import { TagUi } from "../../ui/TagUi";
import {
  EstadoAperturaCajaConstants,
  TipoButtonConstants,
} from "../../../types/typesConstants";
import { open_file } from "../../../helpers/utils";
import { ButtonUi } from "../../ui/ButtonUi";

const handleMenuClick = (e, record, acciones) => {
  const {
    handleAbrirCaja,
    handleArquearCaja,
    handleEditar,
    handleEliminar,
    navigate,
  } = acciones;
  switch (e.key) {
    case "abrircaja":
      handleAbrirCaja(record);
      return navigate("/ventas/puntoventa");
    case "arquearcaja": {
      handleArquearCaja(record);
      return navigate("/caja/arqueocaja");
    }
    case "editar":
      handleEditar(record);
      break;
    case "eliminar":
      handleEliminar(record);
      break;
    default:
      break;
  }
};

const items = () => [
  {
    key: "abrircaja",
    label: "Abrir caja",
    icon: <RightSquareOutlined />,
  },
  {
    key: "arquearcaja",
    label: "Cerrar caja",
    icon: <DollarOutlined />,
  },
  {
    key: "editar",
    label: "Editar",
    icon: <EditOutlined />,
  },
  {
    key: "eliminar",
    label: "Eliminar",
    icon: <DeleteOutlined />,
  },
];

const columns = (acciones) => {
  const { handleReporteArqueoCaja, handleEditarCierreCaja } = acciones;
  return [
    {
      title: "N°",
      dataIndex: "nro",
      key: "nro",
      render: (text, record, index) => <>{index + 1}</>,
    },
    {
      title: "Terminal cajero",
      dataIndex: "nombreterminalcajero",
      key: "nombreterminalcajero",
    },
    {
      title: "Local",
      dataIndex: "local_nombre",
      key: "local_nombre",
    },
    {
      title: "Fecha apertura",
      dataIndex: "fechaapertura",
      key: "fechaapertura",
    },
    {
      title: "Monto Inicial",
      dataIndex: "montoinicial",
      key: "montoinicial",
    },
    {
      title: "Moneda",
      dataIndex: "nombremoneda",
      key: "nombremoneda",
    },
    {
      title: "Turno",
      dataIndex: "nombreturno",
      key: "nombreturno",
    },
    {
      title: "Estado",
      dataIndex: "nombreestadocaja",
      key: "nombreestadocaja",
      render: (text, record) => (
        <TagUi type={record?.estadocaja} content={text} />
      ),
    },
    {
      title: "Acciones",
      dataIndex: "acciones",
      key: "acciones",
      render: (text, record) => (
        <Space size="middle">
          {record?.estadocaja !== EstadoAperturaCajaConstants.Cerrado && (
            <Dropdown
              menu={{
                items: items(record?.estadocaja),
                onClick: (e) => handleMenuClick(e, record, acciones),
              }}
            >
              <Button>
                <Space>
                  Acciones
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          )}
          {record?.estadocaja === EstadoAperturaCajaConstants.Cerrado && (
            <Space wrap>
              <ButtonUi
                typeUi={TipoButtonConstants.EditarTable}
                displayText={false}
                onClick={() => handleEditarCierreCaja(record)}
              />
              <Button
                type="default"
                onClick={() => handleReporteArqueoCaja(record)}
              >
                <FileExcelOutlined style={{ color: "#65B741" }} />
                Reporte arqueo caja
              </Button>
            </Space>
          )}
        </Space>
      ),
    },
  ];
};

export const CajasTable = ({ openDrawer }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { cajas } = useSelector((state) => state.venta);

  const handleAbrirCaja = (data) => {
    localStorage.setItem("caja", JSON.stringify(data));
    dispatch(cajaAbiertaSetActive(data));
  };
  const handleArquearCaja = (data) => {
    localStorage.setItem("caja", JSON.stringify(data));
    dispatch(cajaAbiertaSetActive(data));
  };

  const handleEditar = (data) => {
    dispatch(cajaSetActive(data));
    openDrawer();
  };
  const handleEliminar = (data) => {
    showDeleteConfirm(
      "¿Está seguro de eliminar la caja seleccionada?",
      data.nombreterminalcajero,
      (isConfirmed) => {
        if (isConfirmed) {
          dispatch(cajaStartDelete(data));
        }
      }
    );
  };
  const handleReporteArqueoCaja = async (data) => {
    const file = await file_download_excel_arqueocaja(data?.id);
    const { type: type_file } = file;
    let name_file = "ArqueoCaja-" + data?.arqueocaja?.fechacierre + ".xlsx";
    open_file(file, name_file, type_file);
  };
  const handleEditarCierreCaja = (data) => {
    dispatch(arqueoCajaSetActive(data));
    navigate("/caja/arqueocaja");
  };

  return (
    <TableUi
      columns={columns({
        handleAbrirCaja,
        handleArquearCaja,
        handleEditar,
        handleEliminar,
        navigate,
        handleReporteArqueoCaja,
        handleEditarCierreCaja,
      })}
      data={cajas}
      rowKey="id"
      size="small"
    />
  );
};
