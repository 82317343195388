import React, { useState } from "react";
import { Button, Dropdown, Space, Tooltip } from "antd";
import { useDispatch } from "react-redux";
import {
  productoSetActive,
  productoStartChangeStatusEnabled,
  productoStartDelete,
  productoStartDuplicate,
  showModalListaPrecios,
} from "../../actions/producto";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { uiShowForm } from "../../actions/ui";
import { showConfirm, showDeleteConfirm } from "../../helpers/confirmModal";
import { ButtonUi } from "../ui/ButtonUi";
import { TipoButtonConstants } from "../../types/typesConstants";
import { TagStatusUi } from "../ui/TagStatusUi";
import { TableUi } from "../ui/TableUi";
import { StockInventariosModal } from "./modal/StockInventariosModal";
import { parseNumeric } from "../../helpers/utils";
import { ListaPreciosShowModal } from "./modal/ListaPreciosShowModal";
const key_opts = {
  editar: "Editar",
  eliminar: "Eliminar",
  duplicar: "Duplicar",
  change_status: "change_status",
};

const itemsOptionsTable = (field) => {
  return [
    {
      label: key_opts.editar,
      key: key_opts.editar,
      icon: <EditOutlined />,
    },
    {
      label: key_opts.eliminar,
      key: key_opts.eliminar,
      icon: <DeleteOutlined />,
    },
    {
      label: key_opts.duplicar,
      key: key_opts.duplicar,
      icon: <CopyOutlined />,
    },
    {
      label: field?.estahabilitado === true ? "Inhabilitar" : "Habilitar",
      key: key_opts?.change_status,
      icon:
        field?.estahabilitado === true ? (
          <CloseCircleOutlined />
        ) : (
          <CheckCircleOutlined />
        ),
    },
  ];
};
const columns = ({
  handleShowModalListaPrecios,
  handleMenuItemClick,
  handleShowModalStock,
}) => [
  {
    title: "#",
    dataIndex: "nro",
    key: "nro",
    render: (text, record, index) => <>{index + 1}</>,
  },
  {
    title: "Nombre",
    dataIndex: "nombre",
    key: "nombre",
  },
  {
    title: "SKU",
    dataIndex: "sku",
    key: "sku",
  },
  {
    title: "Tipo",
    dataIndex: "tipoitem_nombre",
    key: "tipoitem_nombre",
  },
  {
    title: "Moneda",
    dataIndex: "moneda_prefijo",
    key: "moneda_prefijo",
    align: "center",
  },
  {
    title: "Precio",
    dataIndex: "precioventa",
    key: "precioventa",
    align: "right",
    render: (value, record) => (
      <Button
        type="link"
        onClick={() => handleShowModalListaPrecios(record)}
        size="small"
      >
        <b>{parseNumeric(value).toFixed(4)}</b>
      </Button>
    ),
  },
  {
    title: "Costo",
    dataIndex: "costo",
    key: "costo",
    align: "right",
  },
  {
    title: "Stock",
    dataIndex: "stockactual",
    key: "stockactual",
    align: "center",
    render: (value, record) => (
      <>
        {record?.esinventariado ? (
          <Button
            type="link"
            onClick={() => handleShowModalStock(record)}
            disabled={!record?.inventarios?.length > 0}
            size="small"
          >
            <b>
              {parseNumeric(value).toFixed(2)}
              <small> {record?.unidadmedida_prefijosunat}</small>
            </b>
          </Button>
        ) : (
          <>--</>
        )}
      </>
    ),
  },
  {
    title: "¿Es inventariado?",
    dataIndex: "esinventariado",
    key: "esinventariado",
    render: (value) => <TagStatusUi status={value} />,
    align: "center",
  },
  {
    title: "¿Se vende?",
    dataIndex: "sevende",
    key: "sevende",
    render: (value) => <TagStatusUi status={value} />,
    align: "center",
  },
  {
    title: "¿Se elabora?",
    dataIndex: "esfabricado",
    key: "esfabricado",
    render: (value) => <TagStatusUi status={value} />,
    align: "center",
  },
  {
    title: "",
    dataIndex: "acciones",
    key: "acciones",
    render: (text, record) => (
      <>
        <Space size="middle">
          <Dropdown
            menu={{
              items: itemsOptionsTable(record),
              onClick: (e) => handleMenuItemClick(e, record),
            }}
            placement="bottomRight"
            arrow={{
              pointAtCenter: true,
            }}
          >
            <ButtonUi
              typeUi={TipoButtonConstants.OpcionesTable}
              displayText={false}
            />
          </Dropdown>
        </Space>
      </>
    ),
  },
];
export const ProductosTable = ({ productos, loading }) => {
  const [open, setOpen] = useState(false);
  const [openModalListaPrecios, setOpenModalListaPrecios] = useState(false);
  const [inventarios, setInventarios] = useState([]);
  const [listaprecios, setListaprecios] = useState([]);
  const dispatch = useDispatch();
  const handleOpenModalInventarios = () => setOpen(true);
  const handleCloseModalInventarios = () => setOpen(false);
  const handleOpenModalListaPrecios = () => setOpenModalListaPrecios(true);
  const handleCloseModalListaPrecios = () => setOpenModalListaPrecios(false);

  const handleEditarProducto = (producto) => {
    dispatch(productoSetActive(producto));
    dispatch(uiShowForm(true));
  };
  const handleDeleteProducto = (producto) => {
    showDeleteConfirm(
      "¿Está seguro de eliminar el producto?",
      producto.nombre,
      (isConfirmed) => {
        if (isConfirmed) {
          dispatch(productoStartDelete(producto));
        }
      }
    );
  };
  const handleDuplicateProducto = (item) => {
    showConfirm(
      "¿Está seguro de duplicar el item seleccionado?",
      item.nombre,
      (isConfirmed) => {
        if (isConfirmed) dispatch(productoStartDuplicate(item?.id));
      }
    );
  };

  const handleEditarListaPrecios = (record) => {
    dispatch(productoSetActive(record));
    dispatch(showModalListaPrecios());
  };
  const handleShowModalListaPrecios = (record) => {
    handleOpenModalListaPrecios();
    setListaprecios(record?.itemlistaprecios);
  };
  const handleShowModalStock = (record) => {
    handleOpenModalInventarios();
    setInventarios(record?.inventarios);
  };
  const handleMenuItemClick = (e, field) => {
    switch (e.key) {
      case key_opts?.editar:
        handleEditarProducto(field);
        break;
      case key_opts?.eliminar:
        handleDeleteProducto(field);
        break;
      case key_opts.duplicar:
        handleDuplicateProducto(field);
        break;
      case key_opts.change_status:
        dispatch(productoStartChangeStatusEnabled(field?.id));
        break;
      default:
        break;
    }
  };

  return (
    <>
      <TableUi
        data={productos}
        columns={columns({
          handleShowModalListaPrecios,
          handleMenuItemClick,
          handleShowModalStock,
        })}
        rowKey="id"
        size="small"
        loading={loading}
        rowClassName={(record) =>
          record?.estahabilitado === false ? "red_color" : ""
        }
        pagination={{
          align: "start",
          showSizeChanger: true,
        }}
      />
      <StockInventariosModal
        inventarios={inventarios}
        open={open}
        onCancel={handleCloseModalInventarios}
      />
      <ListaPreciosShowModal
        listaprecios={listaprecios}
        open={openModalListaPrecios}
        onCancel={handleCloseModalListaPrecios}
      />
    </>
  );
};
