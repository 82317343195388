import React, { useState } from "react";
import { Col, Form, Input, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { uiShowForm } from "../../../actions/ui";
import { FormBasicRegisterUi } from "../../ui/FormBasicRegisterUi";
import { ListaPrecioReglasTable } from "./ListaPrecioReglasTable";
import { ButtonUi } from "../../ui/ButtonUi";
import {
  DateFormats,
  TipoButtonConstants,
  TipoCalculoListaPrecio,
} from "../../../types/typesConstants";
import { ListaPrecioDetailModal } from "./ListaPrecioDetailModal";
import {
  listaprecioClearActive,
  listaprecioStartAdd,
  listaprecioStartUpdate,
  reglaListaprecioClearActive,
} from "../../../actions/listaprecio";
import dayjs from "dayjs";
import { ListaPrecioForm } from "./ListaPrecioForm";

const initialValues = (data = {}) => ({
  id: data?.id || dayjs().valueOf(),
  nombre: data?.nombre || null,
  fechahorainicio:
    (data?.fechahorainicio && dayjs(data?.fechahorainicio)) || null,
  fechahorafin: (data?.fechahorafin && dayjs(data?.fechahorafin)) || null,
  state: data?.state && true,
  tipocalculo: data?.tipocalculo || TipoCalculoListaPrecio.VALOR,
  valorporcentaje: data?.valorporcentaje || null,
  efectocalculoporcentaje: data?.efectocalculoporcentaje || true,
  reglaslistaprecios: data?.reglaslistaprecios || [],
  itemlistaprecios: data?.itemlistaprecios || [],
  listapreciosucursal: data?.listapreciosucursal || [],
});

export const ListaPrecioScreen = () => {
  const { activeListaprecio } = useSelector((state) => state.listaprecio);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const handleCancelModal = () => {
    dispatch(reglaListaprecioClearActive());
    setOpen(false);
  };
  const HandleCloseForm = () => {
    dispatch(listaprecioClearActive());
    dispatch(uiShowForm(false));
  };
  const handleSubmit = (values) => {
    const listapreciosucursal = values?.listapreciosucursal?.map((e) => ({
      local: e?.local ? e?.local : e,
    }));
    const fechahorainicio = values?.fechahorainicio
      ? dayjs(values?.fechahorainicio).format(DateFormats.DatetimeBackEnd)
      : null;
    const fechahorafin = values?.fechahorafin
      ? dayjs(values?.fechahorafin).format(DateFormats.DatetimeBackEnd)
      : null;
    values = {
      ...values,
      fechahorainicio,
      fechahorafin,
      listapreciosucursal,
    };

    if (activeListaprecio) {
      dispatch(listaprecioStartUpdate(values));
    } else {
      dispatch(listaprecioStartAdd(values));
    }
    HandleCloseForm();
  };
  return (
    <>
      <Form.Provider
        onFormFinish={(name, { values, forms }) => {
          if (name === "reglaForm") {
            const { ListaPrecioForm } = forms;
            const reglaslistaprecios =
              ListaPrecioForm.getFieldValue("reglaslistaprecios") || [];

            const _exist =
              reglaslistaprecios.findIndex((item) => item.id === values?.id) >
              -1
                ? true
                : false;
            if (_exist) {
              //Editar
              ListaPrecioForm.setFieldsValue({
                reglaslistaprecios: reglaslistaprecios.map((e) =>
                  e.id === values.id ? values : e
                ),
              });
            } else {
              //Nuevo
              ListaPrecioForm.setFieldsValue({
                reglaslistaprecios: [...reglaslistaprecios, values],
              });
            }
            handleCancelModal();
          }
        }}
      >
        <FormBasicRegisterUi
          name="ListaPrecioForm"
          layout="vertical"
          autoComplete="off"
          initialValues={initialValues(activeListaprecio)}
          handleSubmit={handleSubmit}
          handleClose={HandleCloseForm}
          title="Lista precio"
        >
          <ListaPrecioForm />
        </FormBasicRegisterUi>
        <ListaPrecioDetailModal open={open} onCancel={handleCancelModal} />
      </Form.Provider>
    </>
  );
};
