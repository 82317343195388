import {
  CopyOutlined,
  DiffOutlined,
  DollarCircleOutlined,
} from "@ant-design/icons";
import { Button, Card, Col, Row, Statistic } from "antd";
import React, { useEffect, useState } from "react";
import { CardDetails } from "./CardDetails";
import { FilterDashboardForm } from "./FilterDashboardForm";
import { get_comprobantes_totales_hoy } from "../../actions/dashboard";

const OnLoadTotalesComprobantes_hoy = async (setTotalesComprobantes) => {
  const data = await get_comprobantes_totales_hoy();
  setTotalesComprobantes(data);
};
export const Dashboard = () => {
  const [totalesComprobantes, setTotalesComprobantes] = useState(null);
  useEffect(() => {
    OnLoadTotalesComprobantes_hoy(setTotalesComprobantes);
  }, []);

  return (
    <div>
      <Row gutter={[8, 8]}>
        <Col span={4}>
          <Card>
            <Statistic
              title="Cantidad CPE hoy"
              value={totalesComprobantes?.cpe_hoy?.cantidadtotalcpe || 0}
              precision={2}
              valueStyle={{
                color: "#3498DB",
              }}
              prefix={<DiffOutlined />}
            />
          </Card>
        </Col>
        <Col span={4}>
          <Card>
            <Statistic
              title="Monto total CPE hoy"
              value={totalesComprobantes?.cpe_hoy?.montototalcpe || 0}
              precision={2}
              valueStyle={{
                color: "#3f8600",
              }}
              prefix={"S/"}
            />
          </Card>
        </Col>
        <Col span={4}>
          <Card>
            <Statistic
              title="Cantidad notas ventas hoy"
              value={totalesComprobantes?.nve_hoy?.cantidadtotalnve || 0}
              precision={2}
              valueStyle={{
                color: "#3498DB",
              }}
              prefix={<DiffOutlined />}
            />
          </Card>
        </Col>
        <Col span={4}>
          <Card>
            <Statistic
              title="Monto total notas ventas hoy"
              value={totalesComprobantes?.nve_hoy?.montototalnve || 0}
              precision={2}
              valueStyle={{
                color: "#3f8600",
              }}
              prefix={"S/"}
            />
          </Card>
        </Col>
        <Col span={4}>
          <Card>
            <Statistic
              title="Monto total general"
              value={
                parseFloat(totalesComprobantes?.cpe_hoy?.montototalcpe || 0) +
                parseFloat(totalesComprobantes?.nve_hoy?.montototalnve || 0)
              }
              precision={2}
              valueStyle={{
                color: "#3f8600",
              }}
              prefix={"S/"}
            />
          </Card>
        </Col>
        <Col span={4}>
          <Card>
            <Statistic
              title="Utilidad neta"
              value={11.28}
              precision={2}
              valueStyle={{
                color: "#3f8600",
              }}
              prefix={<DollarCircleOutlined />}
            />
          </Card>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col md={24} lg={24}>
          <FilterDashboardForm />
        </Col>
      </Row>
      <Row className="mt-2" gutter={16}>
        <CardDetails />
      </Row>
    </div>
  );
};

export default Dashboard;
