import { Col, Row } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uiShowForm } from "../../../actions/ui";
import { MovimientoTrasladoDetalleModal } from "./MovimientoTrasladoDetalleModal";
import { MovimientoTrasladoScreen } from "./MovimientoTrasladoScreen";
import { MovimientoTrasladoTable } from "./MovimientoTrasladoTable";
import { PageHeaderUi } from "../../ui/PageHeaderUi";
import { ButtonUi } from "../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../types/typesConstants";
import { MovimientoTrasladoSearchForm } from "./MovimientoTrasladoSearchForm";
import {
  comprobantesMovimientoInitialize,
  movimientosTrasladoStartLoading,
} from "../../../actions/inventario";

const MovimientoTrasladoListScreen = () => {
  const dispatch = useDispatch();
  const { comprobantesMovimientoTraslado, loading } = useSelector(
    (state) => state.inventario
  );
  // useEffect(() => {
  //   dispatch(comprobantesMovimientoInitialize());
  // }, []);

  const onClickNuevoTraslado = () => {
    dispatch(uiShowForm(true));
  };
  return (
    <PageHeaderUi
      title="Movimientos Traslados"
      subTitle="Listado"
      extra={[
        <ButtonUi
          key="1"
          typeUi={TipoButtonConstants.Nuevo}
          onClick={onClickNuevoTraslado}
        />,
      ]}
    >
      <div style={{ overflow: "hidden" }}>
        <Row>
          <Col sm={24} md={24} xl={24}>
            <MovimientoTrasladoSearchForm />
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <MovimientoTrasladoTable
              movimientos={comprobantesMovimientoTraslado}
              loading={loading}
            />
          </Col>
        </Row>
      </div>
      <MovimientoTrasladoDetalleModal />
    </PageHeaderUi>
  );
};

export const MovimientoTrasladoList = () => {
  const { visibleForm } = useSelector((state) => state.ui);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(movimientosTrasladoStartLoading());
  }, []);
  if (visibleForm) return <MovimientoTrasladoScreen />;
  else return <MovimientoTrasladoListScreen />;
};

export default MovimientoTrasladoList;
