import { FilePdfOutlined, MailOutlined } from "@ant-design/icons";
import { Button, Col, Input, message, Modal, Row, Space } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  comprobantepagoClearActive,
  comprobantespagoSendEmail,
  get_pdf_boleta_venta,
  get_pdf_factura_venta,
  get_pdf_nota_venta,
  showModalComprobanteOptionPrint,
} from "../../../actions/comprobante";
import { open_pdf_file_tab, validateEmail } from "../../../helpers/utils";
import {
  TipoButtonConstants,
  TipoComprobanteSunatConstants,
  TypeSizeReportConstants,
} from "../../../types/typesConstants";
import { ButtonUi } from "../../ui/ButtonUi";

export const ComprobantePrintModal = () => {
  const { Search } = Input;
  const dispatch = useDispatch();
  const { activeComprobantePago, visibleModalOptionPrintModal } = useSelector(
    (state) => state.comprobantepago
  );
  const handleCancel = () => {
    dispatch(comprobantepagoClearActive());
    dispatch(showModalComprobanteOptionPrint(false));
  };
  const file_comprobante_pago = async (
    comprobante,
    typesize_report = TypeSizeReportConstants.R_80
  ) => {
    let file = null;
    let exist = true;
    switch (comprobante.tipocomprobantesunat) {
      case TipoComprobanteSunatConstants.Boleta:
        file = await get_pdf_boleta_venta(comprobante?.id, typesize_report);
        break;
      case TipoComprobanteSunatConstants.Factura:
        file = await get_pdf_factura_venta(comprobante?.id, typesize_report);
        break;
      case TipoComprobanteSunatConstants.NotaVenta:
        file = await get_pdf_nota_venta(comprobante?.id, typesize_report);
        break;
      default:
        file = null;
        exist = false;
        break;
    }
    if (exist) open_pdf_file_tab(file);
  };
  const OnHandleSendEmail = (email_receiver) => {
    const valid = validateEmail(email_receiver);
    if (valid) {
      dispatch(
        comprobantespagoSendEmail(activeComprobantePago?.id, email_receiver)
      );
    } else {
      message.error("Ingrese un email válido");
    }
  };
  const OnHandleComprobante_PrintA4 = async () => {
    await file_comprobante_pago(
      activeComprobantePago,
      TypeSizeReportConstants.R_A4
    );
  };
  const OnHandleComprobante_Print80 = async () => {
    await file_comprobante_pago(
      activeComprobantePago,
      TypeSizeReportConstants.R_80
    );
  };
  return (
    <Modal
      title={`${activeComprobantePago?.tipocomprobante_nombre}: ${activeComprobantePago?.comprobante}`}
      centered
      maskClosable={false}
      destroyOnClose
      open={visibleModalOptionPrintModal}
      onCancel={handleCancel}
      //   width="60%"
      footer={[
        <ButtonUi
          key={TipoButtonConstants.Cancelar}
          typeUi={TipoButtonConstants.Cancelar}
          onClick={handleCancel}
        />,
      ]}
    >
      <Row gutter={16} justify="center">
        <Col span={16} style={{ textAlign: "center" }}>
          <Space wrap>
            <Button
              type="info"
              with="dashed"
              onClick={OnHandleComprobante_PrintA4}
            >
              <FilePdfOutlined /> A4
            </Button>
            <Button
              type="info"
              with="dashed"
              onClick={OnHandleComprobante_Print80}
            >
              <FilePdfOutlined /> 80 MM
            </Button>
          </Space>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col md={24}>
          <Search
            enterButton={
              <>
                <MailOutlined />
                {" Enviar"}
              </>
            }
            style={{ width: "90%" }}
            onSearch={OnHandleSendEmail}
          />
        </Col>
      </Row>
    </Modal>
  );
};
