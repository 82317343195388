import { CloseSquareOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Drawer, Space } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { uiShowDrawer } from "../../../../actions/ui";
import { AperturaCajaForm } from "./AperturaCajaForm";
import {
  cajaClearActive,
  cajasStartAdd,
  cajaStartUpdate,
} from "../../../../actions/caja";
import dayjs from "dayjs";
import { FormUi } from "../../../ui/FormUi";
import {
  DateFormats,
  TipoButtonConstants,
} from "../../../../types/typesConstants";
import { ButtonUi } from "../../../ui/ButtonUi";

const initialValues = (data = {}) => ({
  id: data?.id || "",
  fechaapertura: dayjs(data?.fechaapertura) || dayjs(),
  montoinicial: parseFloat(data?.montoinicial) || null,
  turno: data?.turno || null,
  terminalcajero: data?.terminalcajero || null,
  moneda: data?.moneda || null,
});

export const AperturaCajaDrawer = ({ open, handleCloseDrawer }) => {
  const dispatch = useDispatch();

  const { activeEmpleado } = useSelector((state) => state.auth);
  const { activeCaja } = useSelector((state) => state.venta);

  const handleClose = () => {
    dispatch(cajaClearActive());
    handleCloseDrawer();
  };
  const handleSubmit = (values) => {
    values = {
      ...values,
      fechaapertura: dayjs(values.fechaapertura).format(DateFormats.backend),
      cajero: activeEmpleado?.id,
      empresa: activeEmpleado?.empresa?.id,
    };

    if (!activeCaja) {
      dispatch(cajasStartAdd(values));
    } else {
      dispatch(cajaStartUpdate(values));
    }
    handleClose();
  };
  return (
    <Drawer
      title={
        <span>
          Apertura de caja <small>{activeCaja ? "Editar" : "Nuevo"}</small>
        </span>
      }
      maskClosable={false}
      onClose={handleClose}
      open={open}
      size="large"
      destroyOnClose
      styles={{ body: { paddingBottom: 80 } }}
      extra={
        <Space wrap>
          <ButtonUi
            typeUi={TipoButtonConstants.Cancelar}
            onClick={handleClose}
          />
          <ButtonUi
            form="FormAperturaCaja"
            typeUi={TipoButtonConstants.Guardar}
            htmlType="submit"
          />
        </Space>
      }
    >
      <FormUi
        id="FormAperturaCaja"
        name="FormAperturaCaja"
        layout="vertical"
        autoComplete="off"
        handleSubmit={handleSubmit}
        initialValues={initialValues(activeCaja)}
      >
        <AperturaCajaForm />
      </FormUi>
    </Drawer>
  );
};
