import React, { useState } from "react";
import { TableUi } from "../../ui/TableUi";
import { Dropdown, Space, Tag, Tooltip } from "antd";
import { ButtonUi } from "../../ui/ButtonUi";
import {
  DateFormats,
  TipoButtonConstants,
} from "../../../types/typesConstants";
import dayjs from "dayjs";
import {
  CloseOutlined,
  DeleteOutlined,
  EyeOutlined,
  FilePdfOutlined,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { DetalleMovimientoPreviewModal } from "../reusable/DetalleMovimientoPreviewModal";
import { showDeleteConfirm } from "./../../../helpers/confirmModal";
import { useDispatch } from "react-redux";
import {
  comprobanteMovimientoStartAnulado,
  get_pdf_comprobante_movimiento,
} from "../../../actions/inventario";
import { open_pdf_file } from "../../../helpers/utils";
import { TipoComprobanteMovimientoConstants } from "../../../helpers/loadConstants";

const key_opts = {
  eliminar: "Eliminar",
  rptcomprobante: "Comprobante",
  change_status: "change_status",
};

const itemsOptionsTable = (data) => {
  let opts = [];
  if (
    data?.tipocomprobantemovimiento !==
    TipoComprobanteMovimientoConstants.ANULADO
  )
    opts = [
      {
        label: key_opts.eliminar,
        key: key_opts.eliminar,
        icon: <DeleteOutlined />,
      },
    ];
  opts = [
    ...opts,
    {
      label: key_opts.rptcomprobante,
      key: key_opts.rptcomprobante,
      icon: <FilePdfOutlined twoToneColor="#eb2f96" />,
    },
  ];
  return opts;
};
const Tag_TipoMovimiento = (record) => {
  switch (record?.tipocomprobantemovimiento) {
    case TipoComprobanteMovimientoConstants.ENTRADA:
      return (
        <span>
          <Tag color="success">
            <b>
              <PlusOutlined /> {record?.tipocomprobantemovimiento_nombre}
            </b>
          </Tag>
          <h6 style={{ margin: "4px 0px 0px 0px" }}>
            {record?.tipomovimiento_nombre}
          </h6>
        </span>
      );
    case TipoComprobanteMovimientoConstants.SALIDA:
      return (
        <span>
          <Tag color="error">
            <b>
              <MinusOutlined /> {record?.tipocomprobantemovimiento_nombre}
            </b>
          </Tag>
          <h6 style={{ margin: "4px 0px 0px 0px" }}>
            {record?.tipomovimiento_nombre}
          </h6>
        </span>
      );
    default:
      return (
        <span>
          <Tag color="#E4080A">
            <b>
              <CloseOutlined /> {record?.tipocomprobantemovimiento_nombre}
            </b>
          </Tag>
          <h6 style={{ margin: "4px 0px 0px 0px" }}>
            {record?.tipomovimiento_nombre}
          </h6>
        </span>
      );
  }
};
const columns = (acciones) => {
  const { handleShowDetail, handleMenuItemClick } = acciones;
  return [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      width: "5%",
      render: (text, record, i) => <>{i + 1}</>,
    },
    {
      title: "Fecha-Hora",
      dataIndex: "fechahora",
      key: "fechahora",
      align: "center",
      render: (text, record, i) => (
        <>{dayjs(text).format(DateFormats.DatetimeFrontEnd)}</>
      ),
    },
    {
      title: "T.Movimiento",
      dataIndex: "tipocomprobantemovimiento_nombre",
      key: "tipocomprobantemovimiento_nombre",
      render: (text, record, i) => Tag_TipoMovimiento(record),
    },
    {
      title: "Comprobante",
      dataIndex: "comprobantemovimiento",
      key: "comprobantemovimiento",
      align: "center",
    },
    {
      title: "Almacén",
      dataIndex: "almacen_nombre",
      key: "almacen_nombre",
      align: "center",
    },
    {
      title: "Comprobante Asoc.",
      dataIndex: "comprobanteasociado",
      key: "comprobanteasociado",
      align: "center",
      render: (text, record) => {
        return (
          <>
            {record?.tipocomprobanteasociado && (
              <span>
                {record?.comprobanteasociado}
                <h6 style={{ margin: "0px" }}>
                  {record?.tipocomprobanteasociado}
                </h6>
              </span>
            )}
          </>
        );
      },
    },
    {
      title: "Detalle",
      dataIndex: "cantidaditems",
      key: "cantidaditems",
      align: "center",
      render: (text, record, i) => (
        <>
          <ButtonUi
            typeUi={TipoButtonConstants.CustomButton}
            icon={<EyeOutlined />}
            text={record?.detallescomprobantemovimiento?.length}
            size="small"
            onClick={() => handleShowDetail(record)}
          />
        </>
      ),
    },
    {
      title: "Obs.",
      dataIndex: "observacion",
      key: "observacion",
    },
    {
      title: "Acciones",
      dataIndex: "acciones",
      key: "acciones",
      render: (value, record) => (
        <>
          <Space size="middle">
            <Dropdown
              menu={{
                items: itemsOptionsTable(record),
                onClick: (e) => handleMenuItemClick(e, record),
              }}
              placement="bottomRight"
              arrow={{
                pointAtCenter: true,
              }}
            >
              <ButtonUi
                typeUi={TipoButtonConstants.OpcionesTable}
                displayText={false}
              />
            </Dropdown>
          </Space>
        </>
      ),
    },
  ];
};
export const MovimientosTable = ({ data, loading }) => {
  const dispatch = useDispatch();
  const [dataDetalle, setDataDetalle] = useState([]);
  const [open, setOpen] = useState(false);
  const handleOpenModal = () => setOpen(true);
  const handleHideModal = () => setOpen(false);
  const handleEliminar = (data) => {
    showDeleteConfirm(
      "¿Está seguro de anular el comprobante?",
      data?.comprobantemovimiento,
      (confirm) => {
        if (confirm) {
          dispatch(comprobanteMovimientoStartAnulado(data?.id));
        }
      }
    );
  };
  const handleShowDetail = (row) => {
    setDataDetalle(row?.detallescomprobantemovimiento);
    handleOpenModal();
  };
  const handlePDFReporte = async (row) => {
    const data = await get_pdf_comprobante_movimiento(row?.id);
    let name_file = row?.comprobantemovimiento + ".pdf";
    open_pdf_file(data, name_file);
  };
  const handleMenuItemClick = (e, field) => {
    switch (e.key) {
      case key_opts?.eliminar:
        handleEliminar(field);
        break;
      case key_opts.rptcomprobante:
        handlePDFReporte(field);
        break;
      default:
        break;
    }
  };
  return (
    <>
      <TableUi
        size="small"
        rowKey="id"
        loading={loading}
        columns={columns({
          handleEliminar,
          handlePDFReporte,
          handleShowDetail,
          handleMenuItemClick,
        })}
        data={data}
      />
      <DetalleMovimientoPreviewModal
        dataDetalle={dataDetalle}
        open={open}
        handleCancelModal={handleHideModal}
      />
    </>
  );
};
