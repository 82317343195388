import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Space,
  Switch,
  Tooltip,
} from "antd";
import {
  DateFormats,
  MonedaConstants,
  TipoButtonConstants,
} from "../../../types/typesConstants";
import { ButtonUi } from "../../ui/ButtonUi";
import { FormItemHiddenUi } from "../../ui/FormItemHiddenUi";
import { CardUi } from "../../ui/CardUi";
import { DeleteOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { TipoComprobanteSelect } from "../reusable/TipoComprobanteSelect";
import { MonedasSelect } from "../../generales/monedas/MonedasSelect";
import { ComprobanteCompraFormTable } from "./ComprobanteCompraFormTable";
import { MediosPagoComprobanteCompra } from "./MediosPagoComprobanteCompra";
import { ComprobanteCompraFileUpload } from "./ComprobanteCompraFileUpload";
import { ProveedorDropdown } from "../reusable/ProveedorDropdown";
import { SwitchUi } from "./../../ui/SwitchUi";
import { ArchivosComproCompraFileUpload } from "./ArchivosComproCompraFileUpload";

export const ComprobanteCompraForm = ({ onOpenModal, handleCancelForm }) => {
  const form = Form.useFormInstance();
  const detallescomprobantecompra = Form.useWatch(
    "detallescomprobantecompra",
    form
  );
  const tienepagos = Form.useWatch("tienepagos", form);
  const montototal_monedabase = Form.useWatch("montototal_monedabase", form);
  const tieneobservacion = Form.useWatch("tieneobservacion", form);
  const archivo = Form.useWatch("archivo", form);
  const monedaprefijosunat = Form.useWatch("monedaprefijosunat", form);
  const archivos = Form.useWatch("archivos", form);
  const [disabledDetraccion, setDisabledDetraccion] = useState(false);
  const handleNuevoDetalle = () => onOpenModal();
  const handleDeleteFileUpload = () => {
    form.setFieldValue("archivo", null);
  };
  const handleChangeMoneda = (e, field) => {
    const monedaprefijosunat = field?.prefijosunat;
    if (monedaprefijosunat === MonedaConstants.PEN) {
      form.setFieldsValue({ tipocambio: 1 });
    }
    form.setFieldsValue({
      monedaprefijo: field?.prefijo,
      monedaprefijosunat: field?.prefijosunat,
    });
  };
  useEffect(() => {
    if (!isNaN(montototal_monedabase)) {
      if (montototal_monedabase >= 700) setDisabledDetraccion(false);
      else {
        setDisabledDetraccion(true);
        form.setFieldValue("tienedetraccion", false);
      }
    }
  }, [montototal_monedabase]);

  return (
    <CardUi
      key="cardComprobante"
      title="Comprobante compra"
      size="small"
      extra={[
        <Space className="site-button-ghost-wrapper" wrap key="Opts">
          <Tooltip title="Salir" key="1">
            <ButtonUi
              typeUi={TipoButtonConstants.Cancelar}
              onClick={handleCancelForm}
            />
          </Tooltip>
          <Tooltip title="Guardar" key="2">
            <ButtonUi
              htmlType="submit"
              form="FormComprobanteCompra"
              typeUi={TipoButtonConstants.Guardar}
            />
          </Tooltip>
        </Space>,
      ]}
    >
      <Row gutter={[8]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={16}>
          <FormItemHiddenUi name="id" />
          <FormItemHiddenUi name="proveedor" />
          <FormItemHiddenUi name="montototalgravadas" />
          <FormItemHiddenUi name="montototalexoneradas" />
          <FormItemHiddenUi name="montototalinafectas" />
          <FormItemHiddenUi name="montoigvtotal" />
          <FormItemHiddenUi name="montototal" />
          <FormItemHiddenUi name="montodetraccion" />
          <FormItemHiddenUi name="monedaprefijo" />
          <FormItemHiddenUi name="monedaprefijosunat" />
          <FormItemHiddenUi name="montototal_monedabase" />
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24} sm={24} md={12} lg={7} xl={6}>
              <Form.Item
                name="tipocomprobante"
                label="Tipo comprobante"
                rules={[
                  {
                    required: true,
                    message: "Seleccione el tipo de comprobante",
                  },
                ]}
              >
                <TipoComprobanteSelect placeholder="[-Seleccione-]" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={6} xl={6}>
              <Form.Item
                name="nrocomprobante"
                label="Número"
                rules={[
                  { required: true, message: "El número es obligatorio" },
                ]}
              >
                <Input
                  placeholder="Ingrese número"
                  onInput={(e) =>
                    (e.target.value = e.target.value.toUpperCase())
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={5} xl={5}>
              <Form.Item
                name="fechaemision"
                label="Fecha Emisión"
                rules={[
                  {
                    required: true,
                    message: "La fecha de emisión es obligatoria",
                  },
                ]}
                required={false}
              >
                <DatePicker format={DateFormats.frontend} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={5} xl={5}>
              <Form.Item
                name="fechavencimiento"
                label="Fecha Vencimiento"
                rules={[
                  {
                    required: true,
                    message: "La fecha de vencimiento es obligatoria",
                  },
                ]}
                required={false}
              >
                <DatePicker format={DateFormats.frontend} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <ProveedorDropdown
                name="proveedor"
                nameSearchText="proveedor_nombre"
                label="Proveedor"
                placeholder="[-Seleccione-]"
              />
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={5}>
              <Form.Item
                name="moneda"
                label="Moneda"
                rules={[
                  {
                    required: true,
                    message: "La moneda es obligatoria",
                  },
                ]}
                required={false}
              >
                <MonedasSelect
                  placeholder="[-Seleccione-]"
                  onChange={handleChangeMoneda}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={5} xl={5}>
              <Form.Item
                name="tipocambio"
                label="Tipo cambio"
                rules={[
                  {
                    required: true,
                    message: "Campo obligatorio",
                  },
                ]}
                required={false}
                hidden={monedaprefijosunat === MonedaConstants.PEN}
              >
                <InputNumber
                  precision={4}
                  style={{ width: "100%" }}
                  placeholder="Ingrese tipo de cambio"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24} sm={24} md={14} lg={14} xl={14}>
              <Space>
                <Form.Item name="tienedetraccion" valuePropName="checked">
                  <SwitchUi
                    checkedChildren="SI"
                    unCheckedChildren="NO"
                    disabled={disabledDetraccion}
                    onChange={(value) => {
                      if (value) form.setFieldValue("montodetraccion", 0);
                    }}
                  />
                </Form.Item>
                ¿Afecto a detracción?
              </Space>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24} sm={24} md={24} lg={20} xl={20}>
              <Space>
                <Form.Item name="tienepagos" valuePropName="checked">
                  <Switch />
                </Form.Item>
                ¿Desea agregar pagos a esta compra?
              </Space>

              {tienepagos && <MediosPagoComprobanteCompra />}
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24} sm={24} md={14} lg={14} xl={14}>
              <Space>
                <Form.Item name="tieneobservacion" valuePropName="checked">
                  <Switch />
                </Form.Item>
                Añadir observación
              </Space>
            </Col>
          </Row>
          {tieneobservacion && (
            <Row>
              <Col xs={24} sm={24} md={12} lg={10} xl={10}>
                <Form.Item name="observacion">
                  <Input.TextArea rows={2} placeholder="Observaciones" />
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Card
                title={
                  <span>
                    <UnorderedListOutlined /> Detalle comprobante compra
                  </span>
                }
                size="small"
                type="inner"
              >
                <Row gutter={[8, 8]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <ButtonUi
                      typeUi={TipoButtonConstants.AgregarTable}
                      onClick={handleNuevoDetalle}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.List
                      name="detallescomprobantecompra"
                      rules={[
                        {
                          validator: async (_, value) => {
                            if (value < 1)
                              return Promise.reject(
                                new Error(
                                  "Ingrese al menos un item en el detalle de compra"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      {(fields, { add, remove }, { errors }) => (
                        <>
                          <ComprobanteCompraFormTable
                            detallescomprobantecompra={
                              detallescomprobantecompra
                            }
                            handleDelete={remove}
                            handleOpenModal={onOpenModal}
                          />
                          {errors?.length > 0 && (
                            <Form.Item className="ui-errorlist">
                              <Form.ErrorList errors={errors} />
                            </Form.Item>
                          )}
                        </>
                      )}
                    </Form.List>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={8}>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <FormItemHiddenUi name="archivo" />
              <CardUi
                key="archivo"
                type="inner"
                title="Archivo"
                size="small"
                className="DragDropComprobanteCompra"
                extra={
                  archivo?.length > 0 && [
                    <Button
                      key="1"
                      type="link"
                      icon={<DeleteOutlined />}
                      onClick={handleDeleteFileUpload}
                    >
                      Eliminar archivo
                    </Button>,
                  ]
                }
              >
                <ComprobanteCompraFileUpload />
              </CardUi>
            </Col>
            <Col span={24}>
              <FormItemHiddenUi name="archivos" />
              <CardUi
                key="archivo"
                type="inner"
                title="Archivos adicionales"
                size="small"
              >
                <ArchivosComproCompraFileUpload
                  name="archivos"
                  archivos={archivos}
                />
              </CardUi>
            </Col>
          </Row>
        </Col>
      </Row>
    </CardUi>
  );
};
