import { Col, Row, DatePicker, Form, InputNumber } from "antd";
import React from "react";
import { TurnoSelect } from "../../../configuraciones/TurnoSelect";
import { MonedasSelect } from "../../../generales/monedas/MonedasSelect";
import { TerminalCajeroSelect } from "../../reusable/TerminalCajeroSelect";
import { FormItemHiddenUi } from "./../../../ui/FormItemHiddenUi";
import { DateFormats } from "../../../../types/typesConstants";

export const AperturaCajaForm = () => {
  return (
    <>
      <FormItemHiddenUi name="id" />
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            name="fechaapertura"
            label="Fecha apertura"
            rules={[
              { required: true, message: 'La fecha es obligatoria"' },
              { type: "date", message: "Ingrese una fecha validá" },
            ]}
          >
            <DatePicker
              style={{ width: "100%" }}
              format={DateFormats.frontend}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            name="montoinicial"
            label="Monto Inicial"
            rules={[
              {
                required: true,
                type: "number",
                message: "Ingrese un número válido",
              },
            ]}
          >
            <InputNumber
              placeholder="Ingrese monto inicial"
              style={{ width: "100%" }}
              min={0}
              precision={2}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            name="moneda"
            label="Moneda"
            rules={[{ required: true, message: "La moneda es obligatoria" }]}
          >
            <MonedasSelect placeholder="[-Seleccione-]" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            name="terminalcajero"
            label="Terminal cajero"
            rules={[
              { required: true, message: "El terminal cajero es obligatorio" },
            ]}
          >
            <TerminalCajeroSelect placeholder="[-Seleccione-]" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            name="turno"
            label="Turno"
            hasFeedback
            rules={[{ required: true, message: "El turno es obligatorio" }]}
          >
            <TurnoSelect placeholder="[-Seleccione-]" />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
