import { Form, InputNumber } from "antd";
import React, { useEffect, useState } from "react";
import { TipoCalculoListaPrecio } from "../../../types/typesConstants";
import { parseNumeric } from "../../../helpers/utils";

export const ListaPrecioPrecioColumn = ({ field }) => {
  const form = Form.useFormInstance();
  const [disabledPrecio, setDisabledPrecio] = useState(false);
  const precioventa = Form.useWatch("precioventa", form);
  const rowData = Form.useWatch(["itemlistaprecios", field.name], form);
  useEffect(() => {
    if (rowData?.tipocalculo === TipoCalculoListaPrecio.PORCENTAJE) {
      setDisabledPrecio(true);
      const efectocalculoporcentaje = rowData?.efectocalculoporcentaje;
      const _porcentaje = (parseFloat(rowData?.valorporcentaje) || 100) / 100;
      let porcentajecalculo = 1;
      if (efectocalculoporcentaje)
        //Descuento
        porcentajecalculo -= _porcentaje;
      else porcentajecalculo += _porcentaje;
      const nuevoprecio = parseNumeric(precioventa * porcentajecalculo);
      form.setFieldValue(
        ["itemlistaprecios", field.name, "precio"],
        nuevoprecio
      );
    } else setDisabledPrecio(false);
  }, [rowData]);

  return (
    <Form.Item
      name={[field.name, "precio"]}
      rules={[{ required: true, message: "El precio es obligatorio" }]}
      style={{ marginBottom: 0 }}
    >
      <InputNumber
        placeholder="Ingrese precio"
        style={{ width: "100%" }}
        precision={2}
        readOnly={disabledPrecio}
        variant={disabledPrecio ? "borderless" : "outlined"}
      />
    </Form.Item>
  );
};
