import React, { useState } from "react";
import { MovimientoTrasladoForm } from "./MovimientoTrasladoForm";
import { useDispatch, useSelector } from "react-redux";
import { movimientoTrasladoInventarioStartAdd } from "../../../actions/inventario";
import { uiShowForm } from "../../../actions/ui";
import { FormUi } from "../../ui/FormUi";
import { Form, message } from "antd";
import { ItemDetalleModal } from "./modal/ItemDetalleModal";
const initialValues = {
  almacenorigen: null,
  almacendestino: null,
  tieneobservacion: false,
  observacion: null,
  detallescomprobantemovimiento: [],
  manejalotes: null,
  manejaseries: null,
};

export const MovimientoTrasladoScreen = () => {
  const [form] = Form.useForm();
  const almacenorigen = Form.useWatch("almacenorigen", form);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const showModal = () => setOpen(true);
  const hideModal = () => setOpen(false);
  const { activeEmpleado } = useSelector((state) => state.auth);
  const handleSubmit = (values) => {
    const valuesData = {
      empresa: activeEmpleado?.empresa?.id,
      ...values,
    };
    dispatch(movimientoTrasladoInventarioStartAdd(valuesData));
    dispatch(uiShowForm(false));
  };

  return (
    <Form.Provider
      onFormFinish={(name, { values, forms }) => {
        const { FormMovimientoTraslado, ItemDetalleForm } = forms;
        if (name === "ItemDetalleForm") {
          const detallescomprobantemovimiento =
            FormMovimientoTraslado.getFieldValue(
              "detallescomprobantemovimiento"
            ) || [];
          const _existe = detallescomprobantemovimiento?.filter(
            (x) => x.inventario === values?.inventario
          )?.length;
          if (_existe < 1)
            FormMovimientoTraslado.setFieldsValue({
              detallescomprobantemovimiento: [
                ...detallescomprobantemovimiento,
                values,
              ],
            });
          else message.error("el item ya existe en el detalle del movimiento");
          hideModal();
        }
        if (name === "FormSerie") {
          const series = values?.series?.filter(
            (item) => item.selected === true
          );
          ItemDetalleForm.setFieldValue("series", series);
        }
        if (name === "FormLote") {
          const valuesLotes = values?.lotes
            ?.filter((e) => e.cantidad_ingresada !== null)
            .map((e) => ({
              ...e,
              stockactual: e.cantidad,
              cantidad: e.cantidad_ingresada,
            }));
          ItemDetalleForm.setFieldValue("lotes", valuesLotes);
        }
      }}
    >
      <FormUi
        name="FormMovimientoTraslado"
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={initialValues}
        handleSubmit={handleSubmit}
      >
        <MovimientoTrasladoForm onOpenModal={showModal} />
      </FormUi>
      <ItemDetalleModal
        open={open}
        onCancelModal={hideModal}
        almacen={almacenorigen}
      />
    </Form.Provider>
  );
};
