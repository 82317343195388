export const InventarioConstants = {
  TipoOperacionEntrada: "ENT",
  TipoOperacionSalida: "SAL",
};

export const TipoDocumentoPersonaConstants = {
  Dni: "1",
  Ruc: "6",
  CarnetExtranjeria: "4",
};

export const TipoComprobanteSunatConstants = {
  Factura: "01",
  Boleta: "03",
  NotaCredito: "07",
  NotaDebito: "08",
  GuiaRemision: "09",
  NotaVenta: "00",
};
export const EstadoComprobantePagoConstants = {
  EMITIDO: "EMIT",
  ENVIADO: "ENVI",
  ACEPTADO: "ACEP",
  RECHAZADO: "RECH",
};
export const TipoButtonConstants = {
  Nuevo: "1",
  Actualizar: "2",
  Cancelar: "3",
  Filtrar: "4",
  Guardar: "5",
  Eliminar: "6",
  Agregar: "7",
  Disminuir: "8",
  EditarTable: "9",
  EliminarTable: "10",
  AgregarTable: "11",
  CustomButton: "12",
  Iniciar: "13",
  Salir: "14",
  OpcionesTable: "15",
  Buscar: "16",
  Seleccionar: "17",
};

export const SoapTiposConstans = {
  demo: "DEMO",
  produccion: "PROD",
};
export const TipoFiltroBusquedaComprobanteConstants = {
  FECHA_EMISION: "FEMI",
  CLIENTE: "CLIE",
};

export const TypeSizeReportConstants = {
  R_A4: "A4",
  R_A5: "A5",
  R_80: "80",
};
export const TipeFiltroPeriodoConstants = {
  ALL: "ALL",
  ULT_SEMANA: "ULT_SEMANA",
  ENTRE_MES: "ENTRE_MES",
  ENTRE_FECHAS: "ENTRE_FECHAS",
};

export const EstadoAperturaCajaConstants = {
  Abierto: "AB",
  Cerrado: "CE",
  Arqueado: "AR",
};

export const TypeDiscountConstanst = {
  Monto: "AMO",
  Porcentaje: "POR",
};

export const PaymentMethodType = {
  Efectivo: "EFE",
  Tarjeta: "TAR",
  Deposito: "DEP",
};

export const TypePriceCalculationConstants = {
  Monto: "MON",
  Porcentaje: "POR",
};

export const FiltersSearch_Types = {
  CODIGO: "CODIGO",
  NOMBRE: "NOMBRE",
  CATEGORIA: "CATEGORIA",
  PROVEEDOR: "PROVEEDOR",
  FECHAEMISION: "FECHAEMISION",
  FECHAVENCIMIENTO: "FECHAVENCIMIENTO",
  CLIENTE: "CLIENTE",
  COMPROBANTE: "COMPROBANTE",
  TIPOCOMPROBANTE: "TIPOCOMPROBANTE",
  ALMACEN: "ALMACEN",
  LOCAL: "LOCAL",
};

export const DateFormats = {
  frontend: "DD-MM-YYYY",
  backend: "YYYY-MM-DD",
  DatetimeBackEnd: "YYYY-MM-DD HH:mm:ss",
  DatetimeFrontEnd: "DD-MM-YYYY hh:mm a",
};

export const NameFormInventoryMovements = {
  MovimientoEntrada: "Form movimiento entrada",
  MovimientoSalida: "Form movimiento salida",
};

export const EstadoProgramacionTejido = {
  PROCESO: "PROCESO",
  FINALIZADO: "OK",
  ERROR: "ERRADO",
};
export const EstadoProcesoConstants = {
  PROCESO: "PROCESO",
  FINALIZADO: "OK",
};
export const EstadoDetalleProcesoProduccion = {
  INICIADO: "INICIADO",
  PROCESO: "PROCESO",
  FINALIZADO: "FINALIZADO",
};
export const EstadoProcesoProduccionConstants = {
  INICIADO: "INICIADO",
  PROCESO: "PROCESO",
  FINALIZADO: "FINALIZADO",
};
export const EstadoResumenProduccionConstants = {
  PROCESO: "PROCESO",
  FINALIZADO: "FINALIZADO",
};
export const EstadoConsolidadoTelaConstants = {
  PROCESO: "PROCESO",
  FINALIZADO: "FINALIZADO",
};
export const TipoPersonaConstants = {
  NATURAL: "NAT",
  JURIDICA: "JUR",
};
export const TiposClientes = {
  PROVEEDOR: "PRO",
  CLIENTE: "CLI",
  AMBOS: "AMB",
};

export const CondicionPago = {
  CONTADO: "CONTA",
  CREDITO: "CREDI",
};
export const GrupoTipoAfectacionIGV = {
  GRAVADA: "OPE. GRAVADA",
  EXONERADA: "OPE. EXONERADA",
  INAFECTA: "OPE. INAFECTA",
  EXPORTACION: "OPE. EXPORTACION",
  GRATUITA: "OPE. GRATUITA",
};
export const TiposAfectacionIGV = [
  {
    id: "10",
    label: "Gravado",
    escalculado: true,
    grupo: "OPERACION ONEROSA",
    grupo_label: "OPE. GRAVADA",
  },
  {
    id: "20",
    label: "Exonerado",
    escalculado: false,
    grupo: "OPERACION ONEROSA",
    grupo_label: "OPE. EXONERADA",
  },
  {
    id: "30",
    label: "Inafecto",
    escalculado: false,
    grupo: "OPERACION ONEROSA",
    grupo_label: "OPE. INAFECTA",
  },
  {
    id: "40",
    label: "Exportación de bienes o servicios",
    escalculado: false,
    grupo: "OPERACION ONEROSA",
    grupo_label: "EXPORTACION",
  },

  {
    id: "11",
    label: "Gravado - Retiro por premio",
    escalculado: true,
    grupo: "OPERACION GRATUITA",
    grupo_label: "OPE. GRATUITA",
  },
  {
    id: "12",
    label: "Gravado - Retiro por donación",
    escalculado: true,
    grupo: "OPERACION GRATUITA",
    grupo_label: "OPE. GRATUITA",
  },
  {
    id: "13",
    label: "Gravado - Retiro",
    escalculado: true,
    grupo: "OPERACION GRATUITA",
    grupo_label: "OPE. GRATUITA",
  },
  {
    id: "14",
    label: "Gravado - Retiro por publicidad",
    escalculado: true,
    grupo: "OPERACION GRATUITA",
    grupo_label: "OPE. GRATUITA",
  },
  {
    id: "15",
    label: "Gravado - Bonificaciones",
    escalculado: true,
    grupo: "OPERACION GRATUITA",
    grupo_label: "OPE. GRATUITA",
  },
  {
    id: "16",
    label: "Gravado - Retiro por entrega a trabajadores",
    escalculado: true,
    grupo: "OPERACION GRATUITA",
    grupo_label: "OPE. GRATUITA",
  },

  {
    id: "21",
    label: "Exonerado - Transferencia Gratuita",
    escalculado: false,
    grupo: "OPERACION GRATUITA",
    grupo_label: "OPE. GRATUITA",
  },

  {
    id: "31",
    label: "Inafecto - Retiro por Bonificación",
    escalculado: false,
    grupo: "OPERACION GRATUITA",
    grupo_label: "OPE. GRATUITA",
  },
  {
    id: "32",
    label: "Inafecto - Retiro",
    escalculado: false,
    grupo: "OPERACION GRATUITA",
    grupo_label: "OPE. GRATUITA",
  },
  {
    id: "33",
    label: "Inafecto - Retiro por Muestras Médicas",
    escalculado: false,
    grupo: "OPERACION GRATUITA",
    grupo_label: "OPE. GRATUITA",
  },
  {
    id: "34",
    label: "Inafecto - Retiro por Convenio Colectivo",
    escalculado: false,
    grupo: "OPERACION GRATUITA",
    grupo_label: "OPE. GRATUITA",
  },
  {
    id: "35",
    label: "Inafecto - Retiro por premio",
    escalculado: false,
    grupo: "OPERACION GRATUITA",
    grupo_label: "OPE. GRATUITA",
  },
  {
    id: "36",
    label: "Inafecto - Retiro por publicidad",
    escalculado: false,
    grupo: "OPERACION GRATUITA",
    grupo_label: "OPE. GRATUITA",
  },
];

export const EstadoOrdenProduccion = {
  PLANEADO: "PLANEADO",
  PROCESO: "PROCESO",
  FINALIZADO: "FINALIZADO",
  CANCELADO: "CANCELADO",
};

export const GroupsOfUser = {
  VENTAS: "VENTAS",
  COMPRAS: "COMPRAS",
  ALMACEN: "ALMACEN",
  JEFE_VENTAS: "JEFE VENTAS",
};

export const MonedaConstants = {
  PEN: "PEN",
  USD: "USD",
  EUR: "EUR",
};
export const EstadoSerieConstants = {
  EN_STOCK: "STOCK",
  VENDIDO: "VENDI",
  TRASLADO: "TRASLA",
  REMOVIDO: "REMOVE",
};

export const TipoCalculoListaPrecio = {
  VALOR: "VAL",
  PORCENTAJE: "POR",
};
