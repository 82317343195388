import { Form, InputNumber, Space, Tooltip } from "antd";
import React from "react";
import { TableUi } from "../../ui/TableUi";
import { ButtonUi } from "../../ui/ButtonUi";
import { ListaPrecioSelect } from "../reusable/ListaPrecioSelect";
import { TipoButtonConstants } from "../../../types/typesConstants";
import { FormItemHiddenUi } from "../../ui/FormItemHiddenUi";
import Input from "antd/lib/input/Input";
import { ListaPrecioPrecioColumn } from "./ListaPrecioPrecioColumn";

const columns = ({ form, handleEliminar, handleChangeListaPrecio }) => {
  return [
    {
      title: "N°",
      dataIndex: "nro",
      key: "nro",
      render: (text, record, index) => <>{index + 1}</>,
      align: "center",
    },
    {
      title: "Lista precio",
      dataIndex: "listaprecio",
      key: "listaprecio",
      width: "50%",
      render: (text, field, index) => (
        <>
          <FormItemHiddenUi name={[field.name, "tipocalculo"]} />
          {/* <FormItemHiddenUi name={[field.name, "valorporcentaje"]} />
          <FormItemHiddenUi name={[field.name, "efectocalculoporcentaje"]} /> */}
          <Form.Item
            name={[field.name, "listaprecio"]}
            rules={[
              { required: true, message: "La lista de precio es obligatorio" },
            ]}
            style={{ marginBottom: 0, width: "90%" }}
          >
            <ListaPrecioSelect
              placeholder="[-Seleccione-]"
              onChange={(_, opt) => handleChangeListaPrecio(field, opt)}
            />
          </Form.Item>
        </>
      ),
    },
    {
      title: "Tipo calculo",
      dataIndex: "tipocalculo_nombre",
      key: "tipocalculo_nombre",
      render: (text, field) => {
        return (
          <Form.Item name={[field.name, "tipocalculo_nombre"]}>
            <Input variant="borderless" readOnly />
          </Form.Item>
        );
      },
    },
    {
      title: "Precio",
      dataIndex: "precio",
      key: "precio",
      render: (text, field) => {
        return <ListaPrecioPrecioColumn field={field} />;
      },
    },
    {
      title: "Acciones",
      dataIndex: "acciones",
      key: "acciones",
      render: (text, field) => {
        return (
          <Space size="middle">
            <Tooltip title="Eliminar">
              <ButtonUi
                typeUi={TipoButtonConstants.EliminarTable}
                onClick={() => handleEliminar(field)}
                displayText={false}
                ghost
                size="small"
              />
            </Tooltip>
          </Space>
        );
      },
    },
  ];
};

export const ListaPreciosTabContentTable = ({ data, handleDelete, title }) => {
  const form = Form.useFormInstance();

  const handleEliminar = (field) => {
    handleDelete(field?.name);
  };
  const handleChangeListaPrecio = (field, data) => {
    form.setFields([
      {
        name: ["itemlistaprecios", field.name, "tipocalculo"],
        value: data?.tipocalculo,
      },
      {
        name: ["itemlistaprecios", field.name, "tipocalculo_nombre"],
        value: data?.tipocalculo_nombre,
      },
      {
        name: ["itemlistaprecios", field.name, "valorporcentaje"],
        value: data?.valorporcentaje,
      },
      {
        name: ["itemlistaprecios", field.name, "efectocalculoporcentaje"],
        value: data?.efectocalculoporcentaje,
      },
    ]);
  };
  return (
    <TableUi
      data={data}
      columns={columns({ form, handleEliminar, handleChangeListaPrecio })}
      size="small"
      title={title}
    />
  );
};
