import React, { useState } from "react";
import { TableSearchBasicUi } from "../../ui/TableSearchBasicUi";
import { parseNumeric } from "../../../helpers/utils";
const columns = (opts) => [
  {
    title: "#",
    dataIndex: "nro",
    key: "nro",
    render: (text, record, index) => <>{index + 1}</>,
  },
  {
    title: "Descripción",
    dataIndex: "listaprecio_nombre",
    key: "listaprecio_nombre",
    fixed: "left",
    width: "60%",
    filteredValue: [opts?.filteredColumn],
    onFilter: (value, record) =>
      String(record.listaprecio_nombre)
        .toLowerCase()
        .includes(value.toLowerCase()),
  },
  {
    title: "Precio",
    dataIndex: "precio",
    key: "precio",
    align: "right",
    render: (value) => <>{parseNumeric(value).toFixed(4)}</>,
  },
];
export const ListaPreciosTable = ({ listaprecios }) => {
  const [searchText, setSearchText] = useState("");
  const onSearch = (value, e) => {
    e.preventDefault();
    setSearchText(value);
  };
  return (
    <TableSearchBasicUi
      columns={columns({ filteredColumn: searchText })}
      rowKey="id"
      data={listaprecios}
      onSearch={onSearch}
      colSpanSeach={14}
      size="small"
    />
  );
};
