import { UnorderedListOutlined } from "@ant-design/icons";
import { Form, Input, Card, Col, Row, Space, Tooltip } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { terminalCajeroClearActive } from "../../../actions/terminalcajero";
import { EmpleadosSelect } from "../../rrhh/empleados/reusable/EmpleadosSelect";
import { TipoTerminalSelect } from "../reusable/TipoTerminalSelect";
import { TerminalCajeroDetalleTable } from "./TerminalCajeroDetalleTable";
import { ButtonUi } from "../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../types/typesConstants";
import { CardUi } from "./../../ui/CardUi";
import { FormItemHiddenUi } from "./../../ui/FormItemHiddenUi";
import { LocalPuntoVentaSelect } from "./../../generales/locales/reusable/LocalPuntoVentaSelect";

export const TerminalCajeroForm = ({ handleCloseForm, ...props }) => {
  const dispatch = useDispatch();
  const { activeTerminalcajero } = useSelector((state) => state.venta);
  const HandleCloseForm = () => {
    dispatch(terminalCajeroClearActive());
    handleCloseForm();
  };
  return (
    <CardUi
      title={
        <>
          Terminal Cajero
          {activeTerminalcajero ? (
            <small> Editar</small>
          ) : (
            <small> Registrar</small>
          )}
        </>
      }
      size="small"
      extra={
        <Space wrap>
          <Tooltip title="Salir">
            <ButtonUi
              typeUi={TipoButtonConstants.Cancelar}
              onClick={HandleCloseForm}
            />
          </Tooltip>
          <ButtonUi
            typeUi={TipoButtonConstants.Guardar}
            htmlType="submit"
            type="primary"
          />
        </Space>
      }
    >
      <FormItemHiddenUi name="id" />
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Form.Item
            name="nombre"
            label="Nombre"
            rules={[{ required: true, message: "El nombre es obligatorio" }]}
          >
            <Input placeholder="Ingrese nombre del terminal"></Input>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={24} lg={8}>
          <Form.Item
            label="Local"
            name="local"
            rules={[{ required: true, message: "El local es obligatorio" }]}
          >
            <LocalPuntoVentaSelect placeholder="[-Seleccione-]" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={6}>
          <Form.Item
            label="Tipo terminal"
            name="tipoterminalcajero"
            rules={[
              { required: true, message: "El tipo de terminal es obligatorio" },
            ]}
          >
            <TipoTerminalSelect
              name="tipoterminalcajero"
              placeholder="[-Seleccione-]"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <Form.Item
            label="Empleados"
            name="empleados"
            rules={[
              { required: true, message: "Los empleados son obligatorios" },
            ]}
          >
            <EmpleadosSelect
              mode="multiple"
              labelInValue
              allowClear
              style={{ width: "100%" }}
              name="empleados"
              placeholder="[-Seleccione-]"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={32} sm={32} md={32} lg={24} xl={24}>
          <Card
            title={
              <span>
                <UnorderedListOutlined /> Configuración de comprobantes en el
                terminal
              </span>
            }
            size="small"
          >
            <TerminalCajeroDetalleTable {...props} />
          </Card>
        </Col>
      </Row>
    </CardUi>
  );
};
