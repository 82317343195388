import {
  AppstoreOutlined,
  BarsOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Col, Form, Input, Radio, Row } from "antd";
import React, { useEffect, useState } from "react";
import { get_productos_search } from "../../../../actions/comprobante";
import { useDispatch, useSelector } from "react-redux";
import { ProductosList } from "./ProductosList";
import { PosCarritoContent } from "./PosCarritoContent";
import { PosCarritoFooter } from "./PosCarritoFooter";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { CategoryItemSelect } from "../../reusable/CategoryItemSelect";
import { FormUi } from "../../../ui/FormUi";
import { ButtonsChangeSteps } from "../ButtonsChangeSteps";
import { TipoCambioMonedaDiv } from "./TipoCambioMonedaDiv";
import { FormItemHiddenUi } from "../../../ui/FormItemHiddenUi";
import { uiShowLoading } from "../../../../actions/ui";
import { ProductosTable } from "./ProductosTable";

const { Search } = Input;

const initialValues = {
  detallecomprobante: [],
  montosubtotal: 0,
  montoigvtotal: 0,
  montototal: 0,
  moneda: null,
  moneda_prefijo: null,
  moneda_prefijosunat: null,
};

export const BusquedaProductoStep = ({ stepsLength }) => {
  const [form] = Form.useForm();
  const { activeCajaAbierta } = useSelector((state) => state.caja);
  const { currentStep } = useSelector((state) => state.venta);
  const { loading } = useSelector((state) => state.ui);
  const [productos, setProductos] = useState([]);
  const [typeList, setTypeList] = useState("card");
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    name = "",
    category = "",
    code = "",
  } = queryString.parse(location.search);
  const [valuesSearch, setValuesSearch] = useState({
    name,
    category,
    code,
  });

  useEffect(() => {
    if (currentStep?.current === 0) {
      const previewFormValues =
        currentStep?.previewsForm[currentStep?.current]?.previewForm;
      form.setFieldsValue({ ...previewFormValues } || initialValues);
      SearchProduct();
    }
  }, [currentStep?.current]);

  const OnLoadProductos = async () => {
    const { name, category } = valuesSearch;
    dispatch(uiShowLoading());
    const datos = await get_productos_search(
      category,
      name,
      code,
      activeCajaAbierta?.local
    );
    setProductos(datos);
    dispatch(uiShowLoading(false));
  };
  const SearchProduct = () => {
    const { category, name } = valuesSearch;
    if (name.trim().length <= 1) return;
    navigate(`?category=${category}&name=${name}`);
    OnLoadProductos(category, name, "", activeCajaAbierta?.local);
  };
  const handleSeachProduct = (value, _e) => {
    _e.preventDefault();
    SearchProduct();
  };
  const handleChangeName = (e) => {
    setValuesSearch({ ...valuesSearch, name: e.target.value });
  };
  const handleChangeCategory = (value) => {
    setValuesSearch({
      ...valuesSearch,
      category: value,
    });
  };
  const handleTypeList = (e) => {
    setTypeList(e?.target?.value);
  };

  return (
    <FormUi
      form={form}
      name="FormStep1"
      autoComplete="off"
      initialValues={initialValues}
      // handleSubmit={handleSubmit}
    >
      <FormItemHiddenUi name="moneda_prefijo" />
      <FormItemHiddenUi name="moneda_prefijosunat" />
      <Row gutter={[2, 2]} className="pos__content">
        <Col xs={24} sm={24} md={24} lg={24} xl={13} xxl={16}>
          <Row className="pos__producto__search">
            <Col sm={8} lg={8} xl={4}>
              <CategoryItemSelect
                name="category"
                defaultValue="ALL"
                style={{ width: "90%" }}
                value={valuesSearch?.category}
                onChange={handleChangeCategory}
              />
            </Col>
            <Col sm={8} lg={8} xl={16}>
              <Search
                name="name"
                placeholder="Buscar productos"
                allowClear
                enterButton={
                  <>
                    <SearchOutlined /> Buscar
                  </>
                }
                value={valuesSearch?.name}
                onChange={handleChangeName}
                onSearch={handleSeachProduct}
              />
            </Col>
            <Col sm={8} lg={8} xl={4}>
              <Radio.Group value={typeList} onChange={handleTypeList}>
                <Radio.Button value="card">
                  <AppstoreOutlined />
                </Radio.Button>
                <Radio.Button value="list">
                  <BarsOutlined />
                </Radio.Button>
              </Radio.Group>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {typeList === "card" ? (
                <ProductosList productos={productos} loading={loading} />
              ) : (
                <ProductosTable productos={productos} loading={loading} />
              )}
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={11} xxl={8}>
          <TipoCambioMonedaDiv />
          <Row>
            <Col span={24} order={1}>
              <PosCarritoContent />
            </Col>
            <Col span={24} order={2}>
              <PosCarritoFooter />
            </Col>
          </Row>
        </Col>
      </Row>
      <ButtonsChangeSteps stepsLength={stepsLength} />
    </FormUi>
  );
};
