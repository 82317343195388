import React, { useEffect, useState } from "react";
import { CardUi } from "../../../../ui/CardUi";
import {
  Button,
  Col,
  Descriptions,
  Form,
  Input,
  Row,
  Space,
  Tooltip,
} from "antd";
import { ButtonUi } from "../../../../ui/ButtonUi";
import {
  EstadoProgramacionTejido,
  TipoButtonConstants,
} from "../../../../../types/typesConstants";
import { useDispatch, useSelector } from "react-redux";
import {
  programacionTejidoClearActive,
  showFormProgramacionTejido,
} from "../../../../../actions/produccion";
import { FormItemHiddenUi } from "../../../../ui/FormItemHiddenUi";
import { ProveedorSelect } from "../../../reusable/ProveedorSelect";
import { DetalleProgramacionContent } from "./DetalleProgramacionContent";
import { EstadoProgramacionTag } from "../../reusable/EstadoProgramacionTag";
import { SwitchUi } from "./../../../../ui/SwitchUi";
import { parseNumeric } from "../../../../../helpers/utils";
import { SaldosProgramacionTejidoModal } from "./modal/SaldosProgramacionTejidoModal";

const items = ({
  cantidadtotal,
  pesobrutototal,
  pesonetototal,
  saldototal,
}) => [
  {
    key: "cantidadtotal",
    label: "Cantidad",
    children: <>{parseFloat(cantidadtotal).toFixed(2)}</>,
  },
  {
    key: "pesobrutototal",
    label: "Peso bruto total(KG)",
    children: <>{parseFloat(pesobrutototal).toFixed(2)}</>,
  },
  {
    key: "pesonetototal",
    label: "Peso neto total(KG)",
    children: <>{parseFloat(pesonetototal).toFixed(2)}</>,
  },
  {
    key: "saldo",
    label: "Saldo (KG)",
    children: <>{parseFloat(saldototal).toFixed(2)}</>,
  },
];

export const ProgramacionTejidoForm = () => {
  const form = Form.useFormInstance();
  const [disabledControl, setDisabledControl] = useState(false);
  const [openModalSaldos, setOpenModalSaldos] = useState(false);
  const detalleprogramaciontejido = Form.useWatch(
    "detalleprogramaciontejido",
    form
  );
  const saldosprogramaciontejido = Form.useWatch(
    "saldosprogramaciontejido",
    form
  );
  const tieneobservacion = Form.useWatch("tieneobservacion", form);
  const cantidadtotal = Form.useWatch("cantidadtotal", form);
  const pesobrutototal = Form.useWatch("pesobrutototal", form);
  const pesonetototal = Form.useWatch("pesonetototal", form);
  const saldototal = Form.useWatch("saldototal", form);
  const dispatch = useDispatch();
  const { activeProgramacionTejido } = useSelector((state) => state.produccion);
  useEffect(() => {
    if (
      activeProgramacionTejido?.estado === EstadoProgramacionTejido.FINALIZADO
    )
      setDisabledControl(true);
    return () => {
      setDisabledControl(false);
    };
  }, []);

  useEffect(() => {
    let pesobrutototal = 0;
    let pesonetototal = 0;
    let cantidadtotal = 0;
    detalleprogramaciontejido?.forEach((x) => {
      pesobrutototal += parseFloat(x?.pesobruto) || 0;
      pesonetototal += parseFloat(x?.pesoneto) || 0;
      cantidadtotal += parseFloat(x?.cantidad) || 0;
    });
    pesobrutototal = parseNumeric(pesobrutototal);
    pesonetototal = parseNumeric(pesonetototal);
    cantidadtotal = parseNumeric(cantidadtotal);
    form.setFieldsValue({
      pesobrutototal,
      pesonetototal,
      cantidadtotal,
    });
  }, [detalleprogramaciontejido]);
  // useEffect(() => {
  //   form.setFieldValue(
  //     "saldosprogramaciontejido",
  //     activeProgramacionTejido?.saldosprogramaciontejido
  //   );
  // }, [activeProgramacionTejido?.saldosprogramaciontejido]);

  // const handleOpenModalSaldos = () => setOpenModalSaldos(true);
  // const handleCloseModalSaldos = () => setOpenModalSaldos(false);
  const handleCancel = () => {
    dispatch(programacionTejidoClearActive());
    dispatch(showFormProgramacionTejido(false));
  };
  return (
    <>
      <CardUi
        title={
          <Space wrap>
            <span>Programación tejido</span>
            <EstadoProgramacionTag
              estado={
                activeProgramacionTejido
                  ? activeProgramacionTejido?.estado
                  : "nuevo"
              }
              estado_nombre={
                activeProgramacionTejido
                  ? "Estado : " + activeProgramacionTejido?.nombreestado
                  : "Estado : NUEVO"
              }
            />
          </Space>
        }
        size="small"
        extra={[
          <Space className="site-button-ghost-wrapper" wrap key="Opts">
            <Tooltip title="Salir" key="1">
              <ButtonUi
                typeUi={TipoButtonConstants.Cancelar}
                onClick={handleCancel}
              />
            </Tooltip>
            <Tooltip title="Guardar" key="2">
              <ButtonUi
                htmlType="submit"
                form="FormProgramacionTejido"
                typeUi={TipoButtonConstants.Guardar}
              />
            </Tooltip>
          </Space>,
        ]}
      >
        <FormItemHiddenUi name="id" />
        <FormItemHiddenUi name="saldosprogramaciontejido" />
        <FormItemHiddenUi name="cantidadtotal" />
        <FormItemHiddenUi name="pesobrutototal" />
        <FormItemHiddenUi name="pesonetototal" />
        <FormItemHiddenUi name="saldototal" />

        <Row gutter={[8, 8]}>
          <Col xs={24} sm={24} md={24} lg={16} xl={14}>
            <Row>
              <Col span={24}>
                <Form.Item name="proveedor" label="Proveedor">
                  <ProveedorSelect
                    placeholder="Seleccione proveedor"
                    disabled={disabledControl}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Descriptions
                  layout="vertical"
                  size="small"
                  items={items({
                    cantidadtotal,
                    pesobrutototal,
                    pesonetototal,
                    saldototal,
                  })}
                  colon={false}
                  column={{ xs: 2, sm: 2, md: 4, lg: 4, xl: 4, xxl: 4 }}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={10}>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="tieneobservacion"
                  label="Añadir observación"
                  valuePropName="checked"
                >
                  <SwitchUi disabled={disabledControl} />
                </Form.Item>
              </Col>
              {tieneobservacion && (
                <Col span={24}>
                  <Form.Item name="observacion">
                    <Input.TextArea
                      placeholder="Ingrese observaciones"
                      style={{ width: "100%" }}
                      showCount
                      maxLength={250}
                      disabled={disabledControl}
                    />
                  </Form.Item>
                </Col>
              )}
            </Row>
          </Col>
        </Row>

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <DetalleProgramacionContent disabledControl={disabledControl} />
          </Col>
        </Row>
      </CardUi>
      {/* <SaldosProgramacionTejidoModal
        open={openModalSaldos}
        handleCancelModal={handleCloseModalSaldos}
      /> */}
    </>
  );
};
