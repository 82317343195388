import React, { useEffect, useRef } from "react";
import { ModalUi } from "../../../../../ui/ModalUi";
import { TipoButtonConstants } from "../../../../../../types/typesConstants";
import { ButtonUi } from "../../../../../ui/ButtonUi";
import { FormUi } from "../../../../../ui/FormUi";
import { SaldosProgramacionTejidoForm } from "./SaldosProgramacionTejidoForm";
import { Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { saldoProgramacionTejidoClearActive } from "../../../../../../actions/produccion";
import dayjs from "dayjs";
const initialValues = {
  id: dayjs().valueOf(),
  inventario: null,
  item_nombre: null,
  cantidad: null,
  unidadmedida: null,
  estado: null,
};
const useResetFormOnCloseModal = ({
  form,
  open,
  activeSaldoProgramacionTejido,
}) => {
  const prevOpenRef = useRef();
  useEffect(() => {
    prevOpenRef.current = open;
  }, [open]);
  const prevOpen = prevOpenRef.current;
  useEffect(() => {
    if (!open && prevOpen) {
      form.resetFields();
    } else {
      if (activeSaldoProgramacionTejido) {
        form.setFieldsValue({ ...activeSaldoProgramacionTejido });
      }
    }
  }, [form, prevOpen, open]);
};
export const SaldosProgramacionTejidoModal = ({ open, handleCancelModal }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { activeSaldoProgramacionTejido } = useSelector(
    (state) => state.produccion
  );
  useResetFormOnCloseModal({
    form,
    open,
    activeSaldoProgramacionTejido,
  });
  const handleCloseModal = () => {
    dispatch(saldoProgramacionTejidoClearActive());
    handleCancelModal();
  };
  return (
    <ModalUi
      title="Saldos programacion tejido"
      centered
      maskClosable={false}
      destroyOnClose
      open={open}
      onCancel={handleCloseModal}
      width={{
        xs: "90%",
        sm: "80%",
        md: "70%",
        lg: "60%",
        xl: "50%",
        xxl: "40%",
      }}
      footer={[
        <ButtonUi
          key="back"
          typeUi={TipoButtonConstants.Cancelar}
          onClick={handleCloseModal}
        />,
        <ButtonUi
          key="submit"
          htmlType="submit"
          form="FormModalSaldoProgramacionTejido"
          typeUi={TipoButtonConstants.Guardar}
        />,
      ]}
      forceRender
    >
      <FormUi
        name="FormModalSaldoProgramacionTejido"
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={initialValues}
      >
        <SaldosProgramacionTejidoForm />
      </FormUi>
    </ModalUi>
  );
};
