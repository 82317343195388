import { Col, Form, Row, Space } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { uiShowModal } from "../../../../actions/ui";
import { ButtonUi } from "../../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../../types/typesConstants";
import { ClienteSelect } from "../reusable/ClienteSelect";

export const DatosClienteDiv = ({ tipocomprobante }) => {
  const form = Form.useFormInstance();
  const dispatch = useDispatch();
  const OnClienteNuevo = () => {
    dispatch(uiShowModal());
  };
  const OnClienteReset = () => {
    form.setFieldValue("cliente", null);
  };
  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col sm={24} md={12}>
          <Form.Item
            name="cliente"
            shouldUpdate
            rules={[{ required: true, message: "El cliente es obligatorio" }]}
          >
            <ClienteSelect
              tipocomprobante={tipocomprobante}
              style={{ width: "100%" }}
              placeholder="[-Seleccione cliente-]"
              showSearch
            />
          </Form.Item>
        </Col>
        <Col sm={24} md={8}>
          <Space.Compact>
            <ButtonUi
              typeUi={TipoButtonConstants.Nuevo}
              onClick={OnClienteNuevo}
              displayText={false}
            />
            <ButtonUi
              typeUi={TipoButtonConstants.Eliminar}
              onClick={OnClienteReset}
              displayText={false}
            />
          </Space.Compact>
        </Col>
      </Row>
    </>
  );
};
