import React, { useEffect, useState } from "react";
import { SelectUi } from "../../../../../ui/SelectUi";
import { useSelector } from "react-redux";

export const ItemsDevolucionSelect = ({ ...props }) => {
  const { activeProgramacionTejido } = useSelector((state) => state.produccion);
  const [options, setOptions] = useState([]);
  useEffect(() => {
    let opts = [];
    activeProgramacionTejido?.detalleprogramaciontejido?.forEach((rowData) => {
      opts = rowData?.insumosdetalleprogramaciontejido?.map(
        ({
          inventario,
          item,
          almacen_nombre,
          descripcion,
          lote,
          numero_lote,
        }) => ({
          item,
          value: inventario,
          almacen_nombre,
          label: descripcion,
          lote,
          numero_lote,
        })
      );
    });
    setOptions(opts);
  }, []);
  return <SelectUi data={options} showSearch allowClear {...props} />;
};
