import { Col, Divider, Row, DatePicker, Form } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TipeFiltroPeriodoConstants,
  TipoButtonConstants,
} from "../../types/typesConstants";
import { ButtonUi } from "../ui/ButtonUi";
import { AlmacenEmpleadoSelect } from "../ventas/reusable/AlmacenEmpleadoSelect";
import { TiposFiltrosSelect } from "./TiposFiltrosSelect";
import {
  MontosTotalesNotaVentaStartLoad,
  MontosTotalesTipoComprobanteStartLoad,
} from "../../actions/dashboard";
import { FormUi } from "../ui/FormUi";
import dayjs from "dayjs";
import { parseDateFormatBackEnd } from "../../helpers/utils";

const initialValues = {
  establecimiento: null,
  periodo: TipeFiltroPeriodoConstants.ALL,
  fechas: [dayjs(), dayjs()],
};

export const FilterDashboardForm = () => {
  const [form] = Form.useForm();
  const { activeEmpleado } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [visibleFechaInicio, setVisibleFechaInicio] = useState(false);
  const [tipoFecha, setTipoFecha] = useState("ALL");
  const onChangePeriodo = (value) => {
    if (value === TipeFiltroPeriodoConstants.ENTRE_MES) {
      setVisibleFechaInicio(true);
      setTipoFecha("MES");
    } else if (value === TipeFiltroPeriodoConstants.ENTRE_FECHAS) {
      setVisibleFechaInicio(true);
      setTipoFecha("ALL");
    } else if (value === TipeFiltroPeriodoConstants.ULT_SEMANA) {
      setVisibleFechaInicio(false);
      setTipoFecha("ALL");
    } else {
      setVisibleFechaInicio(false);
      setTipoFecha("ALL");
    }
  };

  const OnLoadGraphitsDashboard = (filters) => {
    const rangeFechas = `${parseDateFormatBackEnd(
      filters?.fechas?.[0]
    )}|${parseDateFormatBackEnd(filters?.fechas?.[1])}`;
    dispatch(
      MontosTotalesNotaVentaStartLoad(
        filters?.establecimiento,
        rangeFechas,
        filters?.periodo
      )
    );
    dispatch(
      MontosTotalesTipoComprobanteStartLoad(
        filters?.establecimiento,
        rangeFechas,
        filters?.periodo
      )
    );
  };

  const handleSubmit = (values) => {
    const valuesData = {
      ...values,
      fechainicio: dayjs(values?.fechainicio).format("YYYY-MM-DD"),
      fechafin: dayjs(values?.fechafin).format("YYYY-MM-DD"),
    };
    OnLoadGraphitsDashboard(valuesData);
  };
  return (
    <FormUi
      form={form}
      autoComplete="off"
      name="FormFilterDashboard"
      className="ant-advanced-search-form"
      layout="horizontal"
      initialValues={initialValues}
      handleSubmit={handleSubmit}
    >
      <Divider plain className="ant-divider-ui" style={{ margin: "8px 0" }}>
        Filtros avanzados
      </Divider>
      <Row gutter={24}>
        <Col xs={12} sm={12} md={8} lg={6} xl={6}>
          <Form.Item
            name="establecimiento"
            label="Establecimiento"
            rules={[
              {
                required: true,
                message: "El establecimiento es obligatorio",
              },
            ]}
          >
            <AlmacenEmpleadoSelect
              empleado={activeEmpleado?.id}
              placeholder="Seleccione establecimiento"
              allowClear
            />
          </Form.Item>
        </Col>
        <Col xs={12} sm={12} md={6} lg={4} xl={4}>
          <Form.Item name="periodo" label="Periodo">
            <TiposFiltrosSelect
              placeholder="Seleccione periodo"
              onChange={onChangePeriodo}
            />
          </Form.Item>
        </Col>
        {console.log(visibleFechaInicio)}
        {/* {tipoFecha !== TipeFiltroPeriodoConstants.ALL && (
          <> */}
        <Col xs={24} sm={12} md={8} lg={6} xl={6} hidden={!visibleFechaInicio}>
          <Form.Item
            name="fechas"
            label={
              tipoFecha === TipeFiltroPeriodoConstants.ALL
                ? "Fecha del"
                : "Mes del"
            }
          >
            <DatePicker.RangePicker
              format={
                tipoFecha === TipeFiltroPeriodoConstants.ALL
                  ? "DD/MM/YYYY"
                  : "MM/YYYY"
              }
              picker={
                tipoFecha === TipeFiltroPeriodoConstants.ALL ? "date" : "month"
              }
            />
          </Form.Item>
        </Col>
        <Col xs={6} sm={6} md={4} lg={2} xl={2}>
          <ButtonUi
            key="submit"
            htmlType="submit"
            typeUi={TipoButtonConstants.Filtrar}
          />
        </Col>
      </Row>
    </FormUi>
  );
};
