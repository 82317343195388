import { axiosConToken } from "../helpers/axios";
import { types } from "../types/typesCaja";
import {
  messageLoading,
  messageSuccessLoading,
  validaMessagesErrors,
} from "../helpers/messageConfig";
import { toast } from "react-toastify";
import { uiShowLoading } from "./ui";

const routes = {
  aperturacajas: "aperturacajas/",
  arqueocaja: "arqueocaja/",
  movimientoscaja: "movimientocaja/",
  naturalezapagocaja: "naturalezapagocaja/",
};
export const cajasStartLoad = (cajero, tipofiltro = null, query = null) => {
  return async (dispatch) => {
    try {
      const resp = await axiosConToken.get(routes.aperturacajas, {
        params: { cajero, tipofiltro, query },
      });
      const data = resp.data;
      dispatch(cajaLoaded(data));
    } catch (error) {
      validaMessagesErrors(error);
    }
  };
};

export const cajasStartAdd = (cajaValues) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.post(routes.aperturacajas, cajaValues);
      const { msg, caja } = resp.data;
      dispatch(cajaAdded(caja));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      const errors = error.response;
      validaMessagesErrors(error, msgload);
    }
  };
};
export const cajaStartUpdate = (cajaValues) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.put(
        `${routes.aperturacajas + cajaValues.id}/`,
        cajaValues
      );
      const { msg, caja } = resp.data;
      dispatch(cajaUpdated(caja));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      const errors = error.response;
      validaMessagesErrors(error, msgload);
    }
  };
};
export const cajaStartDelete = (caja) => {
  return async (dispatch) => {
    const msgload = messageLoading("Eliminando...");
    try {
      const resp = await axiosConToken.delete(
        `${routes.aperturacajas + caja.id}/`
      );
      const { msg } = resp.data;
      dispatch(cajaDeleted(caja));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      const errors = error.response;
      validaMessagesErrors(error, msgload);
    }
  };
};

export const arqueoCajaStartAdd = (arqueocajaValues) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.post(
        routes.arqueocaja,
        arqueocajaValues
      );
      const { arqueocaja } = resp.data;
      dispatch(cierreCajaSetDataResult(resp.data));
      dispatch(arqueoCajaSetActive(arqueocaja));
    } catch (error) {
      const errors = error.response;
      validaMessagesErrors(error, msgload);
    }
  };
};

export const arqueoCajaStartUpdate = (arqueocajaValues) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.put(
        `${routes.arqueocaja + arqueocajaValues.id}/`,
        arqueocajaValues
      );
      const { msg, arqueocaja } = resp.data;
      dispatch(cierreCajaSetDataResult({ status: "success", statusText: msg }));
      dispatch(arqueoCajaSetActive(arqueocaja));
    } catch (error) {
      const errors = error.response;
      dispatch(
        cierreCajaSetDataResult({
          status: "500",
          statusText: errors?.statusText,
        })
      );
    } finally {
      toast.dismiss(msgload);
    }
  };
};
const cajaLoaded = (cajas) => ({ type: types.cajaLoaded, payload: cajas });
const cajaAdded = (caja) => ({ type: types.cajaAdded, payload: caja });
const cajaUpdated = (caja) => ({ type: types.cajaUpdated, payload: caja });
const cajaDeleted = (caja) => ({ type: types.cajaDeleted, payload: caja });
export const cajaSetActive = (caja) => ({
  type: types.cajaSetActive,
  payload: caja,
});
export const cajaClearActive = () => ({ type: types.cajaClearActive });
export const cajaAbiertaSetActive = (caja) => ({
  type: types.cajaAbiertaSetActive,
  payload: caja,
});
const arqueoCajaAdded = (arqueocaja) => ({
  type: types.arqueoCajaStartAdd,
  payload: arqueocaja,
});
export const cierreCajaSetCurrentStep = (current) => ({
  type: types.cierreCajaSetCurrentStep,
  payload: current,
});
export const cierreCajaSetDataResult = (result) => ({
  type: types.cierreCajaSetResult,
  payload: result,
});
export const cierreCajaClearActive = () => ({
  type: types.cierreCajaClearActive,
});
export const showFormArqueoCaja = (visible = true) => ({
  type: types.arqueoCajaShowForm,
  payload: visible,
});

export const arqueoCajaSetActive = (arqueocaja) => ({
  type: types.cajaAbiertaSetActive,
  payload: arqueocaja,
});
export const detalleArqueoCajaSetActive = (detallearqueocaja) => ({
  type: types.detalleArqueoCajaSetActive,
  payload: detallearqueocaja,
});
export const detalleArqueoCajaClearActive = () => ({
  type: types.detalleArqueoCajaClearActive,
});

//TODO: Movimientos caja

export const movimientosCajaStartLoad = (cajero) => {
  return async (dispatch) => {
    try {
      const resp = await axiosConToken.get(routes.movimientoscaja, {
        params: { cajero },
      });
      const data = resp.data;
      dispatch(movimientoCajaLoaded(data));
    } catch (error) {
      validaMessagesErrors(error);
    }
  };
};

export const movimientoCajaStartAdd = (movimientoValues) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.post(
        routes.movimientoscaja,
        movimientoValues
      );
      const { msg, movimientocaja } = resp.data;
      dispatch(movimientoCajaAdded(movimientocaja));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      const errors = error.response;
      validaMessagesErrors(error, msgload);
    }
  };
};
export const movimientoCajaStartUpdate = (movimientoValues) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.put(
        `${routes.movimientoscaja + movimientoValues.id}/`,
        movimientoValues
      );
      const { msg, movimientocaja } = resp.data;
      dispatch(movimientoCajaUpdated(movimientocaja));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      const errors = error.response;
      validaMessagesErrors(error, msgload);
    }
  };
};
export const movimientoCajaStartDelete = (movimientoValues) => {
  return async (dispatch) => {
    const msgload = messageLoading("Eliminando...");
    try {
      const resp = await axiosConToken.delete(
        `${routes.movimientoscaja + movimientoValues.id}/`
      );
      const { msg } = resp.data;
      dispatch(movimientoCajaDeleted(movimientoValues));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      const errors = error.response;
      validaMessagesErrors(error, msgload);
    }
  };
};
const movimientoCajaLoaded = (movimientos) => ({
  type: types.movimientoCajaLoaded,
  payload: movimientos,
});
const movimientoCajaAdded = (movimiento) => ({
  type: types.movimientoCajaAdded,
  payload: movimiento,
});
const movimientoCajaUpdated = (movimiento) => ({
  type: types.movimientoCajaUpdated,
  payload: movimiento,
});
const movimientoCajaDeleted = (movimiento) => ({
  type: types.movimientoCajaDeleted,
  payload: movimiento,
});
export const movimientoCajaSetActive = (movimiento) => ({
  type: types.movimientoCajaSetActive,
  payload: movimiento,
});
export const movimientoCajaClearActive = () => ({
  type: types.movimientoCajaClearActive,
});

//TODO: Naturaleza de pago
export const naturalezaPagoCajaStartLoad = () => {
  return async (dispatch) => {
    dispatch(uiShowLoading());
    try {
      const resp = await axiosConToken.get(routes.naturalezapagocaja);
      const data = resp.data;
      dispatch(naturalezaPagoCajaLoaded(data));
    } catch (error) {
      validaMessagesErrors(error);
    } finally {
      dispatch(uiShowLoading(false));
    }
  };
};

export const naturalezaPagoCajaStartAdd = (dataInput) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.post(
        routes.naturalezapagocaja,
        dataInput
      );
      const { msg, naturalezapago } = resp.data;
      dispatch(naturalezaPagoCajaAdded(naturalezapago));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      const errors = error.response;
      validaMessagesErrors(error, msgload);
    }
  };
};
export const naturalezaPagoCajaStartUpdate = (dataInput) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.put(
        `${routes.naturalezapagocaja + dataInput.id}/`,
        dataInput
      );
      const { msg, naturalezapago } = resp.data;
      dispatch(naturalezaPagoCajaUpdated(naturalezapago));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      const errors = error.response;
      validaMessagesErrors(error, msgload);
    }
  };
};
export const naturalezaPagoCajaStartDelete = (dataInput) => {
  return async (dispatch) => {
    const msgload = messageLoading("Eliminando...");
    try {
      const resp = await axiosConToken.delete(
        `${routes.naturalezapagocaja + dataInput.id}/`
      );
      const { msg } = resp.data;
      dispatch(naturalezaPagoCajaDeleted(dataInput));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      const errors = error.response;
      validaMessagesErrors(error, msgload);
    }
  };
};

const naturalezaPagoCajaLoaded = (data) => ({
  type: types.naturalezaPagoCajaLoaded,
  payload: data,
});
const naturalezaPagoCajaAdded = (data) => ({
  type: types.naturalezaPagoCajaAdded,
  payload: data,
});
const naturalezaPagoCajaUpdated = (data) => ({
  type: types.naturalezaPagoCajaUpdated,
  payload: data,
});
const naturalezaPagoCajaDeleted = (data) => ({
  type: types.naturalezaPagoCajaDeleted,
  payload: data,
});
export const naturalezaPagoCajaSetActive = (data) => ({
  type: types.naturalezaPagoCajaSetActive,
  payload: data,
});
export const naturalezaPagoCajaClearActive = () => ({
  type: types.naturalezaPagoCajaClearActive,
});
export const naturalezaPagoCajaSelectUpdate = (update = true) => ({
  type: types.naturalezaPagoCajaSelectUpdate,
  payload: update,
});

// EXPORT ASYNC FUNCTIONS
export const get_tipomovimiento_caja = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.movimientoscaja}get_tipomovimiento_caja/`
    );
    datos = resp.data;
  } catch (error) {
    const errors = error.response.data;
    validaMessagesErrors(errors);
  }
  return datos;
};
export const get_tipodocumentosidentidad = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.movimientoscaja}get_tipodocumentos_identidad/`
    );
    datos = resp.data;
  } catch (error) {
    const errors = error.response.data;
    validaMessagesErrors(errors);
  }
  return datos;
};
export const get_naturalezapago = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.movimientoscaja}get_naturalezapago/`
    );
    datos = resp.data;
  } catch (error) {
    const errors = error.response.data;
    validaMessagesErrors(errors);
  }
  return datos;
};
// EXPORT ASYNC FUNCTIONS
export const get_monedas = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get("arqueocaja/get_monedas/");
    datos = resp.data;
  } catch (error) {
    const errors = error.response.data;
    validaMessagesErrors(errors);
  }
  return datos;
};
export const get_denominacionesmonedas_by_moneda = async (moneda) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.arqueocaja}get_denominacionesmonedas_by_moneda/`,
      {
        params: { moneda },
      }
    );
    datos = resp.data;
  } catch (error) {
    const errors = error.response.data;
    validaMessagesErrors(errors);
  }
  return datos;
};
export const get_comprobantespago_by_aperturacaja = async (aperturacaja) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      "arqueocaja/get_comprobantespago_by_aperturacaja/",
      {
        params: { aperturacaja },
      }
    );
    datos = resp.data;
  } catch (error) {
    const errors = error.response.data;
    validaMessagesErrors(errors);
  }
  return datos;
};

export const get_arqueadorescaja = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get("arqueocaja/get_arqueadorescaja/");
    datos = resp.data;
  } catch (error) {
    const errors = error.response.data;
    validaMessagesErrors(errors);
  }
  return datos;
};

export const file_download_excel_arqueocaja = async (aperturacaja) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      "arqueocaja/get_excel_reportearqueocaja_by_caja",
      {
        responseType: "blob",
        params: { aperturacaja },
      }
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
