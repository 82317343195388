import React, { useEffect, useState } from "react";
import { Form, Radio } from "antd";
import { get_tipocomprobantepagos_puntoventa_by_terminalcajero } from "../../../actions/comprobante";
import { TipoComprobanteSunatConstants } from "../../../types/typesConstants";

export const TipoComprobanteRadio = ({ terminalcajero, ...props }) => {
  const form = Form.useFormInstance();
  const [tiposcomprobantes, setTiposcomprobantes] = useState([]);
  useEffect(() => {
    const OnLoadTipoComprobantesTerminal = async (terminalcajero) => {
      const datos = await get_tipocomprobantepagos_puntoventa_by_terminalcajero(
        terminalcajero
      );
      setTiposcomprobantes(datos);
      const OptionDefault =
        datos.filter((x) => x.pordefecto === true)?.[0] || datos?.[0];
      const tipodocumentosunat = OptionDefault?.tipodocumentosunat;
      form.setFieldsValue({
        tipocomprobante: OptionDefault?.value,
        tipodocumentosunat,
      });
      if (tipodocumentosunat !== TipoComprobanteSunatConstants.Factura)
        form.setFieldValue("cliente", 0);
    };
    if (terminalcajero) OnLoadTipoComprobantesTerminal(terminalcajero);
  }, [terminalcajero]);

  return (
    <Radio.Group
      block
      options={tiposcomprobantes}
      optionType="button"
      buttonStyle="solid"
      {...props}
      onChange={(e) => {
        const _tipocomprobante = e?.target?.value;
        const tipocomprobanteData = tiposcomprobantes.filter(
          (x) => x.value === _tipocomprobante
        )[0];
        const tipodocumentosunat = tipocomprobanteData?.tipodocumentosunat;
        form.setFieldsValue({
          tipocomprobante: _tipocomprobante,
          tipodocumentosunat,
        });
        if (tipodocumentosunat !== TipoComprobanteSunatConstants.Factura)
          form.setFieldValue("cliente", 0);
      }}
    />
  );
};
