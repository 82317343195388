import { Col, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TerminalCajeroScreen } from "./TerminalCajeroScreen";
import { TerminalCajeroTable } from "./TerminalCajeroTable";
import { ButtonUi } from "../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../types/typesConstants";
import { PageHeaderUi } from "../../ui/PageHeaderUi";
import { TerminalCajeroSearchTable } from "./TerminalCajeroSearchTable";
import { terminalesCajeroStartLoad } from "../../../actions/terminalcajero";

const TerminalCajeroListScreen = ({ handleOpenForm }) => {
  const { terminalescajero } = useSelector((state) => state.venta);
  const { loading } = useSelector((state) => state.ui);
  return (
    <>
      <PageHeaderUi
        title="Terminal Cajero"
        subTitle="Listado"
        extra={[
          <Space key="options" wrap>
            <ButtonUi
              key="nuevo"
              typeUi={TipoButtonConstants.Nuevo}
              onClick={handleOpenForm}
            />
          </Space>,
        ]}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={24}>
            <TerminalCajeroSearchTable />
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={24}>
            <TerminalCajeroTable
              terminalescajero={terminalescajero}
              loading={loading}
              handleOpenForm={handleOpenForm}
            />
          </Col>
        </Row>
      </PageHeaderUi>
    </>
  );
};

export const TerminalCajeroList = () => {
  const dispatch = useDispatch();
  const [openForm, setOpenForm] = useState(false);
  const handleOpenForm = () => setOpenForm(true);
  const handleCloseForm = () => setOpenForm(false);
  useEffect(() => {
    dispatch(terminalesCajeroStartLoad());
  }, []);
  if (openForm)
    return <TerminalCajeroScreen handleCloseForm={handleCloseForm} />;
  else
    return (
      <TerminalCajeroListScreen
        handleOpenForm={handleOpenForm}
        handleCloseForm={handleCloseForm}
      />
    );
};

export default TerminalCajeroList;
