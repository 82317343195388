import React from "react";
import { TableUi } from "../../../../ui/TableUi";
import { Space, Tooltip } from "antd";
import { ButtonUi } from "../../../../ui/ButtonUi";
import {
  EstadoProcesoConstants,
  TipoButtonConstants,
} from "../../../../../types/typesConstants";
import { parseNumeric } from "../../../../../helpers/utils";
import { useDispatch } from "react-redux";
import { saldoProgramacionTejidoSetActive } from "./../../../../../actions/produccion";
const columns = (props) => [
  {
    title: "N°",
    dataIndex: "nro",
    key: "nro",
    render: (text, record, index) => <>{index + 1}</>,
  },
  {
    title: "Item a devolver",
    dataIndex: "item_nombre",
    key: "item_nombre",
  },
  {
    title: "Almacen",
    dataIndex: "almacen_nombre",
    key: "almacen_nombre",
  },
  {
    title: "Cantidad",
    dataIndex: "cantidad",
    key: "cantidad",
    align: "right",
    render: (text) => <>{parseNumeric(text).toFixed(2)}</>,
  },
  {
    title: "U.Medida",
    dataIndex: "unidadmedida_prefijo",
    key: "unidadmedida_prefijo",
    align: "center",
  },
  {
    title: "Acciones",
    dataIndex: "acciones",
    key: "acciones",
    render: (text, record, index) =>
      record.estado !== EstadoProcesoConstants.FINALIZADO && (
        <Space size="small">
          <Tooltip title="Editar">
            <ButtonUi
              typeUi={TipoButtonConstants.EditarTable}
              onClick={() => props?.handleEditar(record)}
              displayText={false}
              variant="outlined"
              size="small"
            />
          </Tooltip>
          <Tooltip title="Eliminar">
            <ButtonUi
              typeUi={TipoButtonConstants.EliminarTable}
              onClick={() => props?.handleEliminar(index)}
              displayText={false}
              variant="outlined"
              size="small"
            />
          </Tooltip>
        </Space>
      ),
  },
];

const rowSelection = (handleSelectedRows) => {
  return {
    onChange: (selectedRowKeys, selectedRows) => {
      handleSelectedRows(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.estado === EstadoProcesoConstants.FINALIZADO,
      // Column configuration not to be checked
      name: record.name,
    }),
  };
};
export const SaldosProgramacionTejidoTable = ({
  saldosprogramaciontejido,
  handleDelete,
  handleOpenSaldosModal,
  handleSelectedRows,
}) => {
  const dispatch = useDispatch();
  const handleEliminar = (index) => handleDelete(index);
  const handleEditar = (data) => {
    dispatch(saldoProgramacionTejidoSetActive(data));
    handleOpenSaldosModal();
  };
  return (
    <TableUi
      data={saldosprogramaciontejido}
      columns={columns({ handleEditar, handleEliminar })}
      size="small"
      rowKey="id"
      rowSelection={{
        type: "checkbox",
        ...rowSelection(handleSelectedRows),
      }}
    />
  );
};
