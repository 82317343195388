import React, { useEffect, useState } from "react";
import { CardUi } from "../../../../ui/CardUi";
import {
  Button,
  Col,
  Descriptions,
  Form,
  Row,
  Space,
  Tag,
  Tooltip,
} from "antd";
import { useSelector } from "react-redux";
import { ButtonUi } from "../../../../ui/ButtonUi";
import {
  EstadoProcesoConstants,
  TipoButtonConstants,
} from "../../../../../types/typesConstants";
import { SaldosProgramacionTejidoTable } from "./SaldosProgramacionTejidoTable";
import { PlaySquareOutlined } from "@ant-design/icons";
import { messageError } from "../../../../../helpers/messageConfig";
import { ProcesarSaldosProgramacionTejidoModal } from "./modal/ProcesarSaldosProgramacionTejidoModal";
import { FormItemHiddenUi } from "../../../../ui/FormItemHiddenUi";
import { parseNumeric } from "../../../../../helpers/utils";
const items = (data, saldototal) => [
  {
    key: "1",
    label: "Ruc",
    children: (
      <>
        {data?.proveedor_ruc}-{data?.proveedor_nombre}
      </>
    ),
  },
  {
    key: "2",
    label: "Rollos",
    children: <>{data?.cantidadtotal}</>,
  },
  {
    key: "3",
    label: "Peso bruto(KG)",
    children: <>{data?.pesobrutototal}</>,
  },
  {
    key: "4",
    label: "Peso neto(KG)",
    children: <>{data?.pesonetototal}</>,
  },
  {
    key: "saldototal",
    label: "Saldo total(KG)",
    children: <>{parseNumeric(saldototal).toFixed(2)}</>,
  },
  {
    key: "5",
    label: "Fecha inicio",
    children: <>{data?.fechainicio}</>,
  },
  {
    key: "6",
    label: "Fecha fin",
    children: <>{data?.fechafin}</>,
  },
];
export const SaldosProgramacionTejidoForm = ({
  handleOpenSaldosModal,
  handleCloseForm,
}) => {
  const { activeProgramacionTejido } = useSelector((state) => state.produccion);
  const [existSaldo, setExistSaldo] = useState(false);
  const [selectedRowsSaldosTejido, setSelectedRowsSaldosTejido] = useState([]);
  const [openProcesarModal, setOpenProcesarModal] = useState(false);
  const form = Form.useFormInstance();
  const saldosprogramaciontejido = Form.useWatch(
    "saldosprogramaciontejido",
    form
  );
  const saldototal = Form.useWatch("saldototal", form);
  useEffect(() => {
    const exist = saldosprogramaciontejido?.some(
      (item) => item.estado === EstadoProcesoConstants.PROCESO
    );
    const saldototal = saldosprogramaciontejido?.reduce(
      (suma, item) => suma + parseFloat(item?.cantidad),
      0
    );
    setExistSaldo(exist);
    form.setFieldValue("saldototal", saldototal);
    return () => {
      setExistSaldo(false);
    };
  }, [saldosprogramaciontejido]);
  const handleOpenProcesarModal = () => setOpenProcesarModal(true);
  const handleCloseProcesarModal = () => setOpenProcesarModal(false);
  const handleAplicarCambios = () => {
    if (selectedRowsSaldosTejido?.length > 0) handleOpenProcesarModal();
    else messageError("Seleccione los saldos a procesar en inventario");
  };

  return (
    <>
      <CardUi
        title={
          <Space wrap>
            <span>Saldos de los insumos de programación tejido</span>
            <Tag color="green">{activeProgramacionTejido?.codigo}</Tag>
          </Space>
        }
        size="small"
        extra={[
          <Space className="site-button-ghost-wrapper" wrap key="Opts">
            <Tooltip title="Salir" key="1">
              <ButtonUi
                typeUi={TipoButtonConstants.Cancelar}
                onClick={handleCloseForm}
              />
            </Tooltip>
            <Tooltip title="Guardar" key="2">
              <ButtonUi
                htmlType="submit"
                typeUi={TipoButtonConstants.Guardar}
              />
            </Tooltip>
            {existSaldo && (
              <Tooltip title="Aplicar cambios de Saldo" key="3">
                <Button
                  color="cyan"
                  variant="solid"
                  onClick={handleAplicarCambios}
                >
                  <PlaySquareOutlined />
                  Aplicar cambios en Inventario
                </Button>
              </Tooltip>
            )}
          </Space>,
        ]}
      >
        <FormItemHiddenUi name="saldototal" />
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Descriptions
              title="Información general"
              items={items(activeProgramacionTejido, saldototal)}
              size="small"
              bordered
              styles={{ header: { marginBottom: "10px" } }}
              column={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 3 }}
            />
          </Col>
        </Row>
        <Row gutter={[8, 8]} style={{ marginTop: "8px" }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={20}>
            <Form.List name="saldosprogramaciontejido">
              {(fields, { add, remove }, { errors }) => (
                <>
                  <CardUi
                    title="Detalle de saldos de programación de tejido"
                    size="small"
                    type="inner"
                    extra={
                      <>
                        <ButtonUi
                          typeUi={TipoButtonConstants.Agregar}
                          variant="outlined"
                          size="small"
                          onClick={handleOpenSaldosModal}
                        />
                      </>
                    }
                  >
                    <SaldosProgramacionTejidoTable
                      saldosprogramaciontejido={saldosprogramaciontejido}
                      handleDelete={remove}
                      handleOpenSaldosModal={handleOpenSaldosModal}
                      handleSelectedRows={setSelectedRowsSaldosTejido}
                    />
                  </CardUi>
                </>
              )}
            </Form.List>
          </Col>
        </Row>
      </CardUi>
      <ProcesarSaldosProgramacionTejidoModal
        open={openProcesarModal}
        handleCloseModal={handleCloseProcesarModal}
        handleCloseForm={handleCloseForm}
        saldosprogramaciontejido={selectedRowsSaldosTejido}
      />
    </>
  );
};
