import React from "react";
import { TableUi } from "../../../../ui/TableUi";
import { Form, Space, Typography, Tooltip, Tag } from "antd";
import { ButtonUi } from "../../../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../../../types/typesConstants";
const { Text } = Typography;
const columns = (props) => {
  const { handleEliminar, disabledControl } = props;
  return [
    {
      title: "N°",
      dataIndex: "nro",
      key: "nro",
      render: (text, record, index) => <>{index + 1}</>,
    },
    {
      title: "Insumo",
      dataIndex: "descripcion",
      key: "descripcion",
      render: (text, record) => (
        <>
          {`${text} `}
          {record?.numero_lote && (
            <Tag color="success">Lote: {record?.numero_lote}</Tag>
          )}
          {record?.numero_serie && (
            <Tag color="success">Serie: {record?.numero_serie}</Tag>
          )}
        </>
      ),
    },
    {
      title: "N° Comprobante",
      dataIndex: "nrocomprobantemovimiento",
      key: "nrocomprobantemovimiento",
    },
    {
      title: "Cantidad",
      dataIndex: "cantidad",
      key: "cantidad",
    },
    {
      title: "U.Medida",
      dataIndex: "unidadmedida_prefijo",
      key: "unidadmedida_prefijo",
    },
    {
      title: "Precio",
      dataIndex: "precio",
      key: "precio",
      render: (_, record) => (
        <>
          <Text strong>
            {record.moneda_prefijo} {record.precio}
          </Text>
        </>
      ),
    },
    {
      title: "T.C",
      dataIndex: "tipocambio",
      key: "tipocambio",
    },
    {
      title: "Acciones",
      dataIndex: "acciones",
      key: "acciones",
      render: (text, record, index) => (
        <Space size="middle">
          <Tooltip title="Eliminar">
            <ButtonUi
              typeUi={TipoButtonConstants.EliminarTable}
              onClick={() => handleEliminar(index)}
              displayText={false}
              ghost
              size="small"
              disabled={disabledControl}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];
};
export const InsumosDetalleProgramacionTable = ({
  data,
  handleDelete,
  title,
  disabledControl,
}) => {
  const form = Form.useFormInstance();
  const handleEliminar = (index) => handleDelete(index);
  return (
    <TableUi
      data={data}
      columns={columns({ handleEliminar, disabledControl, form })}
      size="small"
      title={title}
      rowKey="id"
    />
  );
};
