import { Col, Row, Spin, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { DetalleProgramacionTabItem } from "./DetalleProgramacionTabItem";
import dayjs from "dayjs";
import { EstadoProgramacionTejido } from "../../../../../types/typesConstants";
const itemDefault = {
  id: new Date().getTime(),
  tipotela: null,
  cantidadinicial: null,
  cantidadfinal: null,
  pesobruto: null,
  pesoneto: null,
  anchotela: null,
  observacion: null,
  estado: EstadoProgramacionTejido.PROCESO,
  insumosdetalleprogramaciontejido: [],
};
export const DetalleProgramacionTab = ({
  fields,
  handleAdd,
  handleRemove,
  disabledControl,
}) => {
  const [activeKey, setActiveKey] = useState(0);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  useEffect(() => {
    setLoading(true);
    const _items = fields?.map((field, index) => ({
      label: `Tab ${index + 1}`,
      children: <DetalleProgramacionTabItem field={field} />,
      key: index,
      closable: !disabledControl,
    }));
    setItems(_items);
    setLoading(false);
  }, [fields]);

  const handleChangeTab = (key) => setActiveKey(key);
  const handleAddTab = () => {
    handleAdd({ ...itemDefault, id: dayjs().valueOf() });
    setActiveKey(fields.length);
  };
  const onEdit = (targetKey, action, { handleRemove }) => {
    if (action === "remove") {
      handleRemove(targetKey);
      const newTargetKey = targetKey - 1;
      if (newTargetKey > 0) setActiveKey(newTargetKey);
      else setActiveKey(0);
    } else if (action === "add") {
      handleAddTab();
    }
  };

  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col sm={24} md={24} lg={24} xl={24} xxl={20}>
          <Spin spinning={loading}>
            <Tabs
              onChange={handleChangeTab}
              activeKey={activeKey}
              type="editable-card"
              onEdit={(targetKey, action) =>
                onEdit(targetKey, action, { handleRemove })
              }
              items={items}
              size="small"
              hideAdd={disabledControl}
            />
          </Spin>
        </Col>
      </Row>
    </>
  );
};
