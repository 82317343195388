import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { showDeleteConfirm } from "../../../../helpers/confirmModal";
import { Descriptions, Dropdown, Space, Table, Tag } from "antd";
import { ButtonUi } from "../../../ui/ButtonUi";
import {
  DateFormats,
  EstadoProcesoProduccionConstants,
  TipoButtonConstants,
} from "../../../../types/typesConstants";
import dayjs from "dayjs";
import { EstadoProduccionTag } from "./../../reusable/EstadoProduccionTag";
import {
  get_pdf_resumenproduccion,
  resumenProduccionSetActive,
  resumenProduccionStartDelete,
} from "../../../../actions/produccion";
import {
  DeleteOutlined,
  DeliveredProcedureOutlined,
  EditOutlined,
  FileDoneOutlined,
  FilePdfOutlined,
  SwapRightOutlined,
} from "@ant-design/icons";
import { open_file, parseNumeric } from "../../../../helpers/utils";
import { ProcesarResumenModal } from "./modal/ProcesarResumenModal";
import { TableSearchBasicUi } from "../../../ui/TableSearchBasicUi";

const keyActions = {
  editar: "Editar",
  eliminar: "Eliminar",
  procesar: "Procesar resumen",
  pdfresumen: "PDF Resumen",
};
let itemsActions = (record) => {
  let opts = [
    {
      key: keyActions.editar,
      label: "Editar",
      icon: <EditOutlined />,
    },
    {
      key: keyActions.eliminar,
      label: "Eliminar",
      icon: <DeleteOutlined />,
    },
    {
      key: keyActions.procesar,
      label: "Procesar resumen",
      icon: <DeliveredProcedureOutlined />,
    },
    {
      key: keyActions.pdfresumen,
      label: "PDF resumen",
      icon: <FilePdfOutlined />,
    },
  ];
  if (record?.estado === EstadoProcesoProduccionConstants.FINALIZADO)
    opts = [
      {
        key: keyActions.pdfresumen,
        label: "PDF resumen",
        icon: <FilePdfOutlined className="red_color" />,
      },
    ];
  return opts;
};
const handleMenuClick = (e, record, acciones) => {
  const {
    handleEditar,
    handleEliminar,
    handleProcesarResumen,
    handlePDFResumenProduccion,
  } = acciones;
  switch (e.key) {
    case keyActions.editar:
      handleEditar(record);
      break;
    case keyActions.eliminar:
      handleEliminar(record);
      break;
    case keyActions.procesar:
      handleProcesarResumen(record);
      break;
    case keyActions.pdfresumen:
      handlePDFResumenProduccion(record);
      break;
    default:
      break;
  }
};
const items = ({
  costototalmanoobra,
  costototalmateriaprima,
  costototalindirecto,
}) => [
  {
    label: "Costo mano obra",
    children: (
      <div style={{ paddingLeft: "10px" }}>
        <b>S/ {parseNumeric(costototalmanoobra).toFixed(2)}</b>
      </div>
    ),
  },
  {
    label: "Costo materia prima",
    children: (
      <div style={{ paddingLeft: "10px" }}>
        <b>S/ {parseNumeric(costototalmateriaprima).toFixed(2)}</b>
      </div>
    ),
  },
  {
    label: "Costo indirecto",
    children: (
      <div style={{ paddingLeft: "10px" }}>
        <b>S/ {parseNumeric(costototalindirecto).toFixed(2)}</b>
      </div>
    ),
  },
];
const ExpandCostoProduccionDiv = ({ data }) => {
  return (
    <Descriptions
      items={items(data)}
      column={3}
      size="small"
      contentStyle={{ paddingBottom: 0, fontWeight: "bold" }}
    />
  );
};
const columns = (acciones) => [
  {
    title: "#",
    dataIndex: "nro",
    key: "nro",
    render: (text, record, index) => <>{index + 1}</>,
  },
  {
    title: "Descripción",
    dataIndex: "descripcion",
    key: "descripcion",
    filteredValue: [acciones?.filteredColumn],
    onFilter: (value, record) =>
      String(record.descripcion).toLowerCase().includes(value.toLowerCase()) ||
      String(record.estado_nombre).toLowerCase().includes(value.toLowerCase()),
  },
  {
    title: "Fecha Hora Inicio - Fin",
    dataIndex: "fechahorainicio",
    key: "fechahorainicio",
    align: "center",
    render: (text, record) => (
      <>
        {dayjs(text).format(DateFormats.DatetimeFrontEnd)}{" "}
        <SwapRightOutlined
          style={{ fontSize: "16px", color: "#4096ff", fontWeight: "bold" }}
        />{" "}
        {dayjs(record?.fechahorafin).format(DateFormats.DatetimeFrontEnd)}
      </>
    ),
  },
  {
    title: "Costo producción",
    dataIndex: "costototalproduccion",
    key: "costototalproduccion",
    render: (text) => (
      <>
        <b>S/ {parseNumeric(text).toFixed(2)}</b>
      </>
    ),
  },
  Table.EXPAND_COLUMN,
  {
    title: "Estado",
    dataIndex: "estado",
    key: "estado",
    align: "center",
    render: (text, record) => (
      <EstadoProduccionTag
        estado={text}
        estado_nombre={record?.estado_nombre}
      />
    ),
  },
  {
    title: "Mov.Inventario",
    dataIndex: "comprobantemovimiento",
    key: "comprobantemovimiento",
    align: "center",
    render: (text, record) => {
      return (
        <>
          {text && (
            <Tag color="processing">
              <FileDoneOutlined />{" "}
              {"NOTA ENTRADA: " + record?.nrocomprobantemovimiento}
            </Tag>
          )}
        </>
      );
    },
  },
  {
    title: "",
    dataIndex: "opciones",
    key: "opciones",
    render: (text, record) => (
      <Space wrap>
        <Dropdown
          menu={{
            items: itemsActions(record),
            onClick: (e) => handleMenuClick(e, record, acciones),
          }}
          placement="bottomRight"
          arrow={{
            pointAtCenter: true,
          }}
        >
          <ButtonUi
            typeUi={TipoButtonConstants.OpcionesTable}
            displayText={false}
          />
        </Dropdown>
      </Space>
    ),
  },
];

export const ResumenProduccionTable = ({ data, handleOpenForm }) => {
  const [openModal, setOpenModal] = useState(false);
  const [dataResult, setDataResult] = useState(null);
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  const handleEditar = (record) => {
    dispatch(resumenProduccionSetActive(record));
    handleOpenForm();
  };
  const handleEliminar = (record) => {
    showDeleteConfirm(
      "¿Está seguro de eliminar el resumen de producción?",
      record.descripcion,
      (isConfirmed) => {
        if (isConfirmed) {
          dispatch(resumenProduccionStartDelete(record));
        }
      }
    );
  };
  const handlePDFResumenProduccion = async (record) => {
    const file = await get_pdf_resumenproduccion(record?.id);
    const name_file = `Resumen - ${record?.id}`;
    open_file(file, name_file, file?.type);
  };
  const onSearch = (value) => {
    setSearchText(value);
  };
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleProcesarResumen = (record) => {
    setDataResult(record);
    handleOpenModal();
  };
  return (
    <>
      <TableSearchBasicUi
        data={data}
        columns={columns({
          filteredColumn: searchText,
          handleEditar,
          handleEliminar,
          handleProcesarResumen,
          handlePDFResumenProduccion,
        })}
        rowKey="id"
        size="small"
        expandable={{
          expandedRowRender: (record) => (
            <ExpandCostoProduccionDiv data={record} />
          ),
        }}
        scroll={{ x: 900 }}
        onSearch={onSearch}
      />
      <ProcesarResumenModal
        open={openModal}
        data={dataResult}
        handleCloseModal={handleCloseModal}
      />
    </>
  );
};
