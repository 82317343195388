import React, { useEffect, useRef } from "react";
import { CloseOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Form, Modal } from "antd";
import { CotizacionProductoModalForm } from "./CotizacionProductoModalForm";
import dayjs from "dayjs";
import { FormUi } from "../../ui/FormUi";
const initialValues = {
  id: dayjs().valueOf(),
  almacen: null,
  inventario: null,
  item: null,
  item_nombre: null,
  unidadmedida: null,
  unidadmedida_nombre: null,
  cantidad: 1,
  valorunitario: 0,
  preciounitario: 0,
  valorigv: 0,
  subtotalventa: 0,
  totalventa: 0,
  cantidad_almacenes: 0,
};

const useResetFormOnCloseModal = ({ form, open }) => {
  const prevOpenRef = useRef();
  useEffect(() => {
    prevOpenRef.current = open;
  }, [open]);
  const prevOpen = prevOpenRef.current;
  useEffect(() => {
    if (!open && prevOpen) {
      form.resetFields();
      form.setFieldsValue({ id: dayjs().valueOf() });
    }
  }, [form, prevOpen, open]);
};
export const CotizacionProductoModal = ({ open, handleClose }) => {
  const [form] = Form.useForm();
  useResetFormOnCloseModal({ form, open });
  const handleSave = () => handleClose();

  return (
    <Modal
      title="Producto"
      centered
      maskClosable={false}
      destroyOnClose
      open={open}
      onOk={handleSave}
      onCancel={handleClose}
      width="60%"
      footer={[
        <Button
          key="back"
          danger
          icon={<CloseOutlined />}
          onClick={handleClose}
        >
          Cancel
        </Button>,
        <Button
          form="FormProductoCotizacionModal"
          key="submit"
          htmlType="submit"
          type="primary"
          icon={<SaveOutlined />}
        >
          Guardar
        </Button>,
      ]}
    >
      <FormUi
        id="FormProductoCotizacionModal"
        name="FormProductoCotizacionModal"
        layout="vertical"
        autoComplete="off"
        initialValues={initialValues}
        onSubmit={handleSave}
        form={form}
      >
        <CotizacionProductoModalForm />
      </FormUi>
    </Modal>
  );
};
