import { Form } from "antd";
import React from "react";
import { DetalleProgramacionTab } from "./DetalleProgramacionTab";
import { UnorderedListOutlined } from "@ant-design/icons";
import { CardUi } from "../../../../ui/CardUi";

export const DetalleProgramacionContent = ({ disabledControl }) => {
  return (
    <Form.List
      name="detalleprogramaciontejido"
      rules={[
        {
          validator: async (_, value) => {
            if (!value || value.length < 1)
              return Promise.reject(
                new Error(
                  "Se debe ingresar al menos 1 item en el detalle de programación de tejido"
                )
              );
          },
        },
      ]}
    >
      {(fields, { add, remove }) => (
        <>
          <CardUi
            title={
              <span>
                <UnorderedListOutlined /> Detalle programación tejido
              </span>
            }
            size="small"
            type="inner"
          >
            <DetalleProgramacionTab
              fields={fields}
              handleAdd={add}
              handleRemove={remove}
              disabledControl={disabledControl}
            />
          </CardUi>
        </>
      )}
    </Form.List>
  );
};
