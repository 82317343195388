import React from "react";
import { SearchFormUi } from "../../ui/SearchFormUi";
import { Col, DatePicker, Form, Row } from "antd";
import {
  DateFormats,
  FiltersSearch_Types,
  TipoButtonConstants,
} from "../../../types/typesConstants";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { parseDateFormatBackEnd } from "../../../helpers/utils";
import { cajasStartLoad } from "../../../actions/caja";
import { SelectUi } from "../../ui/SelectUi";
import { ButtonUi } from "../../ui/ButtonUi";
import { PuntoVentaEmpleadoSelect } from "./../../generales/locales/reusable/PuntoVentaEmpleadoSelect";
const initialValues = {
  tipofiltro: FiltersSearch_Types.FECHAEMISION,
  fechaapertura: [dayjs(), dayjs()],
  local: null,
};
const _tipofiltro = [
  {
    value: FiltersSearch_Types.FECHAEMISION,
    label: "FECHA APERTURA",
  },
  {
    value: FiltersSearch_Types.LOCAL,
    label: "LOCAL",
  },
];
export const CajasSearchFormTable = () => {
  const [form] = Form.useForm();
  const tipofiltro = Form.useWatch("tipofiltro", form);
  const dispatch = useDispatch();
  const { activeEmpleado } = useSelector((state) => state.auth);
  const handleSubmit = (values) => {
    let query = null;
    if (tipofiltro === FiltersSearch_Types.FECHAEMISION)
      query = `${parseDateFormatBackEnd(
        values?.fechaapertura?.[0]
      )}|${parseDateFormatBackEnd(values?.fechaapertura?.[1])}`;
    else if (tipofiltro === FiltersSearch_Types.LOCAL) query = values?.local;

    dispatch(cajasStartLoad(activeEmpleado?.id, values?.tipofiltro, query));
  };
  return (
    <SearchFormUi
      name="FormSearchMovimientos"
      form={form}
      autoComplete="off"
      initialValues={initialValues}
      handleSubmit={handleSubmit}
    >
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={16} md={12} lg={8} xl={6}>
          <Form.Item name="tipofiltro" label="Buscar por">
            <SelectUi data={_tipofiltro} allowClear placeholder="[-TODOS-]" />
          </Form.Item>
        </Col>
        {tipofiltro === FiltersSearch_Types.FECHAEMISION && (
          <Col xs={24} sm={16} md={12} lg={8} xl={8}>
            <Form.Item
              name="fechaapertura"
              label="Fecha apertura"
              rules={[
                {
                  required: FiltersSearch_Types.FECHAEMISION,
                  message: "La fecha de apertura es obligatorio",
                },
              ]}
            >
              <DatePicker.RangePicker format={DateFormats.frontend} />
            </Form.Item>
          </Col>
        )}
        {tipofiltro === FiltersSearch_Types.LOCAL && (
          <Col xs={24} sm={16} md={12} lg={8} xl={8}>
            <Form.Item
              name="local"
              label="Local"
              rules={[
                {
                  required: tipofiltro === FiltersSearch_Types.LOCAL,
                  message: "El local es obligatorio",
                },
              ]}
            >
              <PuntoVentaEmpleadoSelect empleado={activeEmpleado?.id} />
            </Form.Item>
          </Col>
        )}
        <Col xs={24} sm={8} md={12} lg={6} xl={6}>
          <Form.Item>
            <ButtonUi typeUi={TipoButtonConstants.Filtrar} htmlType="submit" />
          </Form.Item>
        </Col>
      </Row>
    </SearchFormUi>
  );
};
