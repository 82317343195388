import React, { useEffect, useState } from "react";
import { get_unidadesmedida } from "../../../actions/producto";
import { SelectUi } from "./../../ui/SelectUi";

export const UnidadMedidaSelect = ({ ...props }) => {
  const [unidadesmedida, setUnidadesmedida] = useState([]);
  const UnidadMedidaLoad = async () => {
    const data = await get_unidadesmedida();
    setUnidadesmedida(data);
  };
  useEffect(() => {
    UnidadMedidaLoad();
  }, []);

  return <SelectUi data={unidadesmedida} {...props} />;
};
