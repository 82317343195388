import { Col, DatePicker, Form, Input, Row } from "antd";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { SelectUi } from "../../ui/SelectUi";
import { SearchOutlined } from "@ant-design/icons";
import {
  DateFormats,
  FiltersSearch_Types,
  TipoButtonConstants,
} from "../../../types/typesConstants";
import { ButtonUi } from "../../ui/ButtonUi";
import { FormUi } from "../../ui/FormUi";
import { useDispatch } from "react-redux";
import { comprobantecompraStartLoad } from "../../../actions/compra";
import { parseDateFormatBackEnd } from "../../../helpers/utils";
const initialValues = {
  filter_type: FiltersSearch_Types.FECHAEMISION,
  proveedor: null,
  fechaemision: [dayjs(), dayjs()],
  fechavencimiento: [dayjs(), dayjs()],
  comprobante: null,
};
const OptionsBusqueda = [
  { value: FiltersSearch_Types.FECHAEMISION, label: "Fecha Emisión" },
  { value: FiltersSearch_Types.FECHAVENCIMIENTO, label: "Fecha Vencimiento" },
  { value: FiltersSearch_Types.PROVEEDOR, label: "Proveedor" },
  { value: FiltersSearch_Types.COMPROBANTE, label: "Comprobante" },
];
export const ComprobanteCompraSearchList = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const filter_type = Form.useWatch("filter_type", form);

  const handleSubmit = (values) => {
    let query = null;
    if (filter_type === FiltersSearch_Types.PROVEEDOR)
      query = values?.proveedor;
    else if (filter_type === FiltersSearch_Types.FECHAEMISION) {
      query = `${parseDateFormatBackEnd(
        values?.fechaemision?.[0]
      )}|${parseDateFormatBackEnd(values?.fechaemision?.[1])}`;
    } else if (filter_type === FiltersSearch_Types.FECHAVENCIMIENTO) {
      query = `${parseDateFormatBackEnd(
        values?.fechavencimiento?.[0]
      )}|${parseDateFormatBackEnd(values?.fechavencimiento?.[1])}`;
    } else if (filter_type === FiltersSearch_Types.COMPROBANTE)
      query = values?.comprobante;

    dispatch(comprobantecompraStartLoad(filter_type, query));
  };
  return (
    <FormUi
      name="advanced_search"
      className="ant-advanced-search-form"
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      form={form}
    >
      <Row>
        <Col xs={12} sm={12} md={8} lg={6} xl={6}>
          <Form.Item name="filter_type" label="Filtrar por">
            <SelectUi
              placeholder="Filtrar por"
              data={OptionsBusqueda}
              allowClear
            />
          </Form.Item>
        </Col>
        {filter_type === FiltersSearch_Types.PROVEEDOR && (
          <Col xs={12} sm={12} md={8} lg={4} xl={4} offset={1}>
            <Form.Item name="proveedor">
              <Input
                placeholder="Ingrese ruc o nombre de proveedor"
                allowClear
                addonAfter={<SearchOutlined />}
              />
            </Form.Item>
          </Col>
        )}
        {filter_type === FiltersSearch_Types.FECHAEMISION && (
          <Col xs={12} sm={12} md={8} lg={4} xl={4} offset={1}>
            <Form.Item name="fechaemision">
              <DatePicker.RangePicker format={DateFormats.frontend} />
            </Form.Item>
          </Col>
        )}
        {filter_type === FiltersSearch_Types.FECHAVENCIMIENTO && (
          <Col xs={12} sm={12} md={8} lg={4} xl={4} offset={1}>
            <Form.Item name="fechavencimiento">
              <DatePicker.RangePicker format={DateFormats.frontend} />
            </Form.Item>
          </Col>
        )}
        {filter_type === FiltersSearch_Types.COMPROBANTE && (
          <Col xs={12} sm={12} md={8} lg={4} xl={4} offset={1}>
            <Form.Item name="comprobante">
              <Input
                placeholder="Ingrese comprobante"
                allowClear
                addonAfter={<SearchOutlined />}
              />
            </Form.Item>
          </Col>
        )}
        <Col xs={12} sm={12} md={8} lg={4} xl={4} offset={1}>
          <ButtonUi htmlType="submit" typeUi={TipoButtonConstants.Filtrar} />
        </Col>
      </Row>
    </FormUi>
  );
};
