import { Col, Form, Row } from "antd";
import React from "react";
import { AlmacenEmpleadoSelect } from "./../../../../../ventas/reusable/AlmacenEmpleadoSelect";
import { useSelector } from "react-redux";
import { TableUi } from "../../../../../ui/TableUi";
import { parseNumeric } from "../../../../../../helpers/utils";
import { TipoMovimientoDevolucionSelect } from "../../../../../inventario/reusable/TipoMovimientoDevolucionSelect";
const columns = [
  {
    title: "N°",
    dataIndex: "nro",
    key: "nro",
    render: (text, record, index) => <>{index + 1}</>,
  },
  {
    title: "Item a devolver",
    dataIndex: "item_nombre",
    key: "item_nombre",
  },
  {
    title: "Almacen",
    dataIndex: "almacen_nombre",
    key: "almacen_nombre",
  },
  {
    title: "Cantidad",
    dataIndex: "cantidad",
    key: "cantidad",
    align: "right",
    render: (text) => <>{parseNumeric(text).toFixed(2)}</>,
  },
  {
    title: "U.Medida",
    dataIndex: "unidadmedida_prefijo",
    key: "unidadmedida_prefijo",
    align: "center",
  },
];
export const ProcesarSaldosProgramacionTejidoForm = ({
  saldosprogramaciontejido,
}) => {
  const { activeEmpleado } = useSelector((state) => state.auth);
  return (
    <>
      <Row gutter={[8, 8]}>
        <Col span={12}>
          <Form.Item
            name="almacen"
            label="Almacén"
            rules={[{ required: true, message: "Seleccione el almacén" }]}
          >
            <AlmacenEmpleadoSelect
              placeholder="[-Seleccionar-]"
              empleado={activeEmpleado?.id}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="tipomovimiento"
            label="Tipo movimiento"
            rules={[
              { required: true, message: "Seleccione el tipo movimiento" },
            ]}
          >
            <TipoMovimientoDevolucionSelect placeholder="[-Seleccionar-]" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <TableUi
            data={saldosprogramaciontejido}
            columns={columns}
            size="small"
            rowKey="id"
            title={() => <b>Saldos de items a procesar</b>}
          />
        </Col>
      </Row>
    </>
  );
};
