import React from "react";
import { FormUi } from "../../../../ui/FormUi";
import { Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { ProgramacionTejidoForm } from "./ProgramacionTejidoForm";
import {
  detalleProgramacionTejidoClearActive,
  programacionTejidoClearActive,
  programacionTejidoStartAdd,
  programacionTejidoStartUpdate,
  showFormProgramacionTejido,
} from "../../../../../actions/produccion";
import { messageError } from "../../../../../helpers/messageConfig";

const initialValues = (data = {}) => ({
  id: data?.id || null,
  cantidadtotal: data?.cantidadtotal || 0,
  pesobrutototal: data?.pesobrutototal || 0,
  pesonetototal: data?.pesonetototal || 0,
  saldototal: data?.saldototal || 0,
  proveedor: data?.proveedor || null,
  detalleprogramaciontejido: data?.detalleprogramaciontejido || [
    {
      id: new Date().getTime(),
      tipotela: null,
      cantidad: null,
      pesobruto: null,
      pesoneto: null,
      anchotela: null,
      tipocambio: null,
      observacion: null,
      estado: false,
      insumosdetalleprogramaciontejido: [],
    },
  ],
  estado: data?.estado || null,
  tieneobservacion: data?.observacion?.length > 0 || false,
  observacion: data?.observacion || null,
  saldosprogramaciontejido: data?.saldosprogramaciontejido || [],
});

export const ProgramacionTejidoScreen = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { activeEmpleado } = useSelector((state) => state.auth);
  const { activeProgramacionTejido, activeDetalleProgramacionTejido } =
    useSelector((state) => state.produccion);

  const handleSave = (values) => {
    const valuesData = { ...values, empresa: activeEmpleado?.empresa?.id };
    if (activeProgramacionTejido)
      dispatch(programacionTejidoStartUpdate(valuesData));
    else dispatch(programacionTejidoStartAdd(valuesData));

    dispatch(showFormProgramacionTejido(false));
    dispatch(programacionTejidoClearActive());
  };
  return (
    <Form.Provider
      onFormFinish={(name, { values, forms }) => {
        const { FormProgramacionTejido } = forms;
        if (
          name ===
          `DetalleComprobanteMovimientoForm${values?.detalleprogramaciontejido}`
        ) {
          let detalleprogramaciontejido =
            FormProgramacionTejido.getFieldValue("detalleprogramaciontejido") ||
            [];
          let _valida_existencia = true;

          detalleprogramaciontejido = detalleprogramaciontejido.map((e) => {
            if (e?.id === values?.detalleprogramaciontejido) {
              let _insumosdetalleprogramaciontejido =
                e?.insumosdetalleprogramaciontejido || [];
              const _existe =
                _insumosdetalleprogramaciontejido?.findIndex(
                  (x) =>
                    x.detallecomprobantemovimiento ===
                    values?.detallecomprobantemovimiento
                ) > -1
                  ? true
                  : false;
              if (!_existe) {
                _insumosdetalleprogramaciontejido?.push(values);
              } else {
                _valida_existencia = false;
                return false;
              }
              return {
                ...e,
                insumosdetalleprogramaciontejido: [
                  ..._insumosdetalleprogramaciontejido,
                ],
              };
            } else return e;
          });
          if (_valida_existencia) {
            FormProgramacionTejido.setFieldValue(
              "detalleprogramaciontejido",
              detalleprogramaciontejido
            );
          } else {
            messageError("El insumo seleccionado ya fue registrado...");
          }
        }
        if (name === "FormComprobanteCompra") {
          const data = {
            ...activeDetalleProgramacionTejido,
            detallecomprobantecompra: values?.detallecomprobantecompra?.id,
            detallecomprobantecompra_nombre:
              values?.detallecomprobantecompra?.descripcion_label,
            detallecomprobantecompra_preciounidad:
              values?.detallecomprobantecompra?.preciounidad,
          };
          let detalleprogramaciontejido =
            FormProgramacionTejido.getFieldValue("detalleprogramaciontejido") ||
            [];
          FormProgramacionTejido.setFieldsValue({
            detalleprogramaciontejido: detalleprogramaciontejido.map((e) =>
              e.id === data.id ? data : e
            ),
          });
          dispatch(detalleProgramacionTejidoClearActive());
        }
      }}
    >
      <FormUi
        name="FormProgramacionTejido"
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={initialValues(activeProgramacionTejido)}
        handleSubmit={handleSave}
      >
        <ProgramacionTejidoForm />
      </FormUi>
    </Form.Provider>
  );
};
