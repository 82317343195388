import React, { useEffect } from "react";
import { PageHeaderUi } from "../../ui/PageHeaderUi";
import { ButtonUi } from "../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../types/typesConstants";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "antd";
import { ComprobanteCompraTable } from "./ComprobanteCompraTable";
import {
  comprobantecompraStartLoad,
  showFormComprobanteCompra,
} from "../../../actions/compra";
import { ComprobanteCompraScreen } from "./ComprobanteCompraScreen";
import { ComprobanteCompraSearchList } from "./ComprobanteCompraSearchList";

const ComprobanteCompraListScreen = () => {
  const dispatch = useDispatch();
  const { comprobantesCompra, loading } = useSelector((state) => state.compra);
  const handleReLoad = () => {
    dispatch(comprobantecompraStartLoad());
  };
  const handleNewComprobante = () => {
    dispatch(showFormComprobanteCompra());
  };

  return (
    <PageHeaderUi
      title="Comprobantes compra"
      subTitle="Listado"
      extra={[
        <ButtonUi
          key="actualizar"
          typeUi={TipoButtonConstants.Actualizar}
          onClick={handleReLoad}
        />,
        <ButtonUi
          key="nuevo"
          typeUi={TipoButtonConstants.Nuevo}
          onClick={handleNewComprobante}
        />,
      ]}
    >
      <div style={{ overflow: "hidden" }}>
        <ComprobanteCompraSearchList />
        <Row>
          <Col sm={32} md={32} xl={24}>
            <ComprobanteCompraTable
              comprobantes={comprobantesCompra}
              loading={loading}
            />
          </Col>
        </Row>
      </div>
    </PageHeaderUi>
  );
};

export const ComprobanteCompraList = () => {
  const { visibleFormComprobanteCompra } = useSelector((state) => state.compra);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(comprobantecompraStartLoad());
  }, []);
  if (visibleFormComprobanteCompra) {
    return <ComprobanteCompraScreen />;
  } else {
    return <ComprobanteCompraListScreen />;
  }
};

export default ComprobanteCompraList;
