import { Form, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { parseNumeric } from "../../../helpers/utils";
import { MonedaConstants } from "../../../types/typesConstants";

const RowTotalesAfectacionIGV = ({ totales }) => (
  <>
    {totales?.map((e, index) => (
      <Table.Summary.Row key={index}>
        <Table.Summary.Cell index={0} colSpan={6} align="right">
          <b>{e?.label}</b>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={6} align="right">
          {e.total}
        </Table.Summary.Cell>
      </Table.Summary.Row>
    ))}
  </>
);

const RowTotalesDetraccion = ({
  totalesdetraccion,
  monedabase_prefijo,
  moneda_prefijo,
  moneda_prefijosunat,
}) => (
  <>
    <Table.Summary.Row key="detra_1">
      <Table.Summary.Cell index={0} colSpan={6} align="right">
        <b>Monto detracción</b>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={6} align="right">
        {moneda_prefijosunat === MonedaConstants.PEN && (
          <div>
            <b>{monedabase_prefijo}</b>
            {` ${parseFloat(totalesdetraccion?.montodetraccion).toFixed(2)}`}
          </div>
        )}
        {moneda_prefijosunat !== MonedaConstants.PEN && (
          <>
            <div>
              <b>{monedabase_prefijo}</b>
              {` ${parseFloat(totalesdetraccion?.montodetraccion).toFixed(2)}`}
            </div>
            <sub>
              <b>{moneda_prefijo}</b>
              {` ${parseFloat(totalesdetraccion?.montodetraccion_other).toFixed(
                2
              )}`}
            </sub>
          </>
        )}
      </Table.Summary.Cell>
    </Table.Summary.Row>
    <Table.Summary.Row key="detra_2">
      <Table.Summary.Cell index={0} colSpan={6} align="right">
        <b>Monto deposito</b>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={6} align="right">
        {moneda_prefijosunat === MonedaConstants.PEN && (
          <div>
            <b>{moneda_prefijo}</b>
            {` ${parseFloat(totalesdetraccion?.montodeposito_proveedor).toFixed(
              2
            )}`}
          </div>
        )}
        {moneda_prefijosunat !== MonedaConstants.PEN && (
          <>
            <div>
              <b>{monedabase_prefijo}</b>
              {` ${parseFloat(
                totalesdetraccion?.montodeposito_proveedor_soles
              ).toFixed(2)}`}
            </div>
            <sub>
              <b>{moneda_prefijo}</b>
              {` ${parseFloat(
                totalesdetraccion?.montodeposito_proveedor
              ).toFixed(2)}`}
            </sub>
          </>
        )}
      </Table.Summary.Cell>
    </Table.Summary.Row>
  </>
);

const fn_CalculoDetraccion = (
  montototal = 0,
  montototal_monedabase = 0,
  monedaprefijosunat,
  tipocambio
) => {
  /* Calculo de la detracción*/
  let montodetraccion = 0;
  let montodetraccion_other = 0;
  let montodeposito_proveedor = 0;
  let montodeposito_proveedor_soles = 0;
  if (monedaprefijosunat === MonedaConstants.PEN) {
    /*Es la moneda base - SOLES*/

    if (montototal >= 700) {
      montodetraccion = parseNumeric(montototal * 0.1, 0);
    }
  } else {
    tipocambio = parseFloat(tipocambio);
    montodetraccion = parseNumeric(montototal_monedabase * 0.1, 0);
    montodetraccion_other = parseNumeric(montodetraccion / tipocambio);
  }

  montodeposito_proveedor = montototal - montodetraccion_other;
  montodeposito_proveedor_soles = montototal_monedabase - montodetraccion;

  return {
    montodetraccion,
    montodetraccion_other,
    montodeposito_proveedor,
    montodeposito_proveedor_soles,
  };
};

export const ComprobanteCompraTableSummary = () => {
  const form = Form.useFormInstance();
  const { activeEmpleado } = useSelector((state) => state.auth);
  const detallescomprobantecompra = Form.useWatch(
    "detallescomprobantecompra",
    form
  );
  const moneda = Form.useWatch("moneda", form);
  const monedaprefijo = Form.useWatch("monedaprefijo", form);
  const monedaprefijosunat = Form.useWatch("monedaprefijosunat", form);
  const tienedetraccion = Form.useWatch("tienedetraccion", form);
  const montodetraccion = Form.useWatch("montodetraccion", form);
  const tipocambio = Form.useWatch("tipocambio", form);
  const montototal = Form.useWatch("montototal", form);
  const montototal_monedabase = Form.useWatch("montototal_monedabase", form);
  const [sumTotalesAfectacionIGV, setSumTotalesAfectacionIGV] = useState([]);
  const [sumTotales, setSumTotales] = useState(null);
  const [sumTotalesDetraccion, setSumTotalesDetraccion] = useState(null);
  const fn_CalcularTotales = (data, monedaprefijo, tipocambio) => {
    let montototalgravadas = 0;
    let montototalinafectas = 0;
    let montototalexoneradas = 0;
    let montototalgratuitas = 0;
    let montototalexportaciones = 0;
    let montoigvtotal = 0;
    let montototal = 0;
    data?.forEach((e) => {
      if (e?.grupotipoafectacionigv === "OPE. GRAVADA") {
        montototalgravadas += parseFloat(e.valorcomprabruto);
      } else if (e?.grupotipoafectacionigv === "OPE. EXONERADA") {
        montototalexoneradas += parseFloat(e.valorcomprabruto);
      } else if (e?.grupotipoafectacionigv === "OPE. INAFECTA") {
        montototalinafectas += parseFloat(e.valorcomprabruto);
      } else if (e?.grupotipoafectacionigv === "EXPORTACION") {
        montototalexportaciones += parseFloat(e.valorcomprabruto);
      } else if (e?.grupotipoafectacionigv === "OPE. GRATUITA") {
        montototalgratuitas += parseFloat(e.valorcomprabruto);
      }
      montoigvtotal += parseFloat(e.valorigv);
      montototal += parseFloat(e.valorcompra);
    });
    montototalgravadas = parseNumeric(montototalgravadas, 2);
    montototalexoneradas = parseNumeric(montototalexoneradas, 2);
    montototalinafectas = parseNumeric(montototalinafectas, 2);
    montoigvtotal = parseNumeric(montoigvtotal, 2);
    montototal = parseNumeric(montototal, 2);
    let totales = [];
    if (montototalgravadas > 0) {
      totales = [
        ...totales,
        {
          label: `Ope. Gravada ${monedaprefijo}`,
          total: montototalgravadas.toFixed(2),
        },
      ];
    }
    if (montototalexoneradas > 0) {
      totales = [
        ...totales,
        {
          label: `Ope. Exonerada ${monedaprefijo}`,
          total: montototalexoneradas.toFixed(2),
        },
      ];
    }
    if (montototalinafectas > 0) {
      totales = [
        ...totales,
        {
          label: `Ope. Inafecta ${monedaprefijo}`,
          total: montototalinafectas.toFixed(2),
        },
      ];
    }

    const montototal_monedabase = parseNumeric(
      montototal * Number(tipocambio),
      2
    );
    setSumTotalesAfectacionIGV(totales);
    setSumTotales({ montoigvtotal, montototal, montototal_monedabase });
    form.setFieldsValue({
      montototalgravadas,
      montototalexoneradas,
      montototalinafectas,
      montoigvtotal,
      montototal,
      montototal_monedabase,
    });
  };
  useEffect(() => {
    fn_CalcularTotales(detallescomprobantecompra, monedaprefijo, tipocambio);
  }, [detallescomprobantecompra, monedaprefijo, tipocambio]);

  useEffect(() => {
    let _montodetraccion = 0;
    let _montodetraccion_other = 0;
    let _montodeposito_proveedor = 0;
    let _montodeposito_proveedor_soles = 0;

    if (tienedetraccion) {
      const result = fn_CalculoDetraccion(
        montototal,
        montototal_monedabase,
        monedaprefijosunat,
        tipocambio
      );
      _montodetraccion = result?.montodetraccion;
      _montodetraccion_other = result?.montodetraccion_other;
      _montodeposito_proveedor = result?.montodeposito_proveedor;
      _montodeposito_proveedor_soles = result?.montodeposito_proveedor_soles;
    }
    setSumTotalesDetraccion({
      montodetraccion: _montodetraccion,
      montodetraccion_other: _montodetraccion_other,
      montodeposito_proveedor: _montodeposito_proveedor,
      montodeposito_proveedor_soles: _montodeposito_proveedor_soles,
    });
    form.setFieldValue("montodetraccion", _montodetraccion);
  }, [montototal, montototal_monedabase, tienedetraccion, tipocambio]);

  return (
    <>
      <RowTotalesAfectacionIGV totales={sumTotalesAfectacionIGV} />
      <Table.Summary.Row>
        <Table.Summary.Cell index={0} colSpan={6} align="right">
          <b>IGV {monedaprefijo}</b>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={6} align="right">
          {parseFloat(sumTotales?.montoigvtotal).toFixed(2)}
        </Table.Summary.Cell>
      </Table.Summary.Row>
      <Table.Summary.Row>
        <Table.Summary.Cell index={0} colSpan={6} align="right">
          <b>Importe Total {monedaprefijo}</b>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={6} align="right">
          {parseFloat(sumTotales?.montototal).toFixed(2)}
        </Table.Summary.Cell>
      </Table.Summary.Row>
      {moneda !== activeEmpleado?.empresa?.moneda && (
        <Table.Summary.Row>
          <Table.Summary.Cell index={0} colSpan={6} align="right">
            <b>Importe Total {activeEmpleado?.empresa?.moneda_prefijo}</b>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={6} align="right">
            {parseFloat(sumTotales?.montototal_monedabase).toFixed(2)}
          </Table.Summary.Cell>
        </Table.Summary.Row>
      )}
      {tienedetraccion && montodetraccion > 0 && (
        <RowTotalesDetraccion
          totalesdetraccion={sumTotalesDetraccion}
          monedabase_prefijo={activeEmpleado?.empresa?.moneda_prefijo}
          moneda_prefijo={monedaprefijo}
          moneda_prefijosunat={monedaprefijosunat}
        />
      )}
    </>
  );
};
