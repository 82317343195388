import { axiosConToken } from "../helpers/axios";
import { types } from "../types/typesGenerales";
import {
  messageLoading,
  messageSuccessLoading,
  validaMessagesErrors,
} from "../helpers/messageConfig";

const key = "msg";
const routes = {
  locales: "locales/",
};
export const localesStartLoad = () => {
  return async (dispatch) => {
    try {
      const resp = await axiosConToken.get(`${routes.locales}`);
      const data = resp.data;
      dispatch(localLoaded(data));
    } catch (error) {
      validaMessagesErrors(error, key);
    }
  };
};
export const localStartAdd = (data) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.post(`${routes.locales}`, data);
      const { msg, local } = resp.data;
      dispatch(localAdded(local));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, msgload);
    }
  };
};
export const localStartUpdate = (data) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.put(
        `${routes.locales}${data.id}/`,
        data
      );
      const { msg, local } = resp.data;
      dispatch(localUpdated(local));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, msgload);
    }
  };
};
export const localStartDelete = (data) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.delete(`${routes.locales}${data.id}/`);
      const { msg } = resp.data;
      dispatch(localDeleted(data));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, msgload);
    }
  };
};
const localLoaded = (locales) => ({
  type: types.localLoaded,
  payload: locales,
});
const localAdded = (local) => ({ type: types.localAdded, payload: local });
const localUpdated = (local) => ({ type: types.localUpdated, payload: local });
const localDeleted = (local) => ({ type: types.localDeleted, payload: local });
export const localSetActive = (local) => ({
  type: types.localSetActive,
  payload: local,
});
export const localClearActive = () => ({ type: types.localClearActive });

export const get_locales = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(`${routes.locales}get_locales/`);
    datos = resp.data;
  } catch (error) {
    const errors = error.response;
    validaMessagesErrors(errors);
  }
  return datos;
};
export const get_locales_puntoventa = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.locales}get_locales_puntoventa/`
    );
    datos = resp.data;
  } catch (errors) {
    validaMessagesErrors(errors);
  }
  return datos;
};
const get_puntosventa_by_empleado = async (empleado) => {
  try {
    const resp = await axiosConToken.get(
      `${routes.locales}get_puntosventa_by_empleado/`,
      {
        params: { empleado },
      }
    );
    return resp.data;
  } catch (errors) {
    validaMessagesErrors(errors);
  }
};
export { get_puntosventa_by_empleado };
