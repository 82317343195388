import React, { useEffect, useState } from "react";
import { get_tipos_calculo } from "../../../../actions/listaprecio";
import { SelectUi } from "../../../ui/SelectUi";
export const TipoCalculoSelect = ({ ...restProps }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const OnLoad = async () => {
      const datos = await get_tipos_calculo();
      setData(datos);
    };
    OnLoad();

    return () => {
      setData([]);
    };
  }, []);
  return <SelectUi data={data} {...restProps} />;
};
