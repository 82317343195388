import React from "react";
import { TableUi } from "../../ui/TableUi";
import { Space, Tag } from "antd";
import {
  DateFormats,
  TipoCalculoListaPrecio,
} from "../../../types/typesConstants";
import { useDispatch } from "react-redux";
import {
  listaprecioSetActive,
  listaprecioStartDelete,
} from "../../../actions/listaprecio";
import { uiShowForm } from "../../../actions/ui";
import { showDeleteConfirm } from "../../../helpers/confirmModal";
import {
  CheckCircleOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  MinusOutlined,
  PlusOutlined,
  SwapRightOutlined,
} from "@ant-design/icons";
import { ActionsDropdownUi } from "../../ui/ActionsDropdownUi";
import dayjs from "dayjs";

const itemsOptionsTable = [
  {
    label: "Editar",
    key: "editar",
    icon: <EditOutlined />,
  },
  {
    label: "Duplicar",
    key: "duplicar",
    icon: <CopyOutlined />,
  },
  {
    label: "Eliminar",
    key: "eliminar",
    icon: <DeleteOutlined />,
  },
];
const columns = (acciones) => {
  const { handleMenuItemClick } = acciones;
  return [
    {
      title: "N°",
      dataIndex: "nro",
      key: "nro",
      render: (text, record, index) => <>{index + 1}</>,
    },
    {
      title: "Nombre Emisión",
      dataIndex: "nombre",
      key: "nombre",
    },
    {
      title: "Tipo Calculo",
      dataIndex: "tipocalculo_nombre",
      key: "tipocalculo_nombre",
      render: (text, record) => (
        <>
          {text}{" "}
          {record?.tipocalculo === TipoCalculoListaPrecio.PORCENTAJE && (
            <>
              {record?.efectocalculoporcentaje ? (
                <Tag color="red">
                  {" "}
                  <MinusOutlined /> {record?.valorporcentaje} %
                </Tag>
              ) : (
                <Tag color="green">
                  {" "}
                  <PlusOutlined /> {record?.valorporcentaje} %
                </Tag>
              )}
            </>
          )}
        </>
      ),
    },
    {
      title: "¿Activo?",
      dataIndex: "state",
      key: "state",
      render: (text, record) => (
        <Tag
          icon={text && <CheckCircleOutlined />}
          color={text ? "success" : "default"}
        >
          {text ? "Si" : "No"}
        </Tag>
      ),
    },
    {
      title: "Fecha Hora Inicio - Fin",
      dataIndex: "fechahorainicio",
      key: "fechahorainicio",
      align: "center",
      render: (text, record) => (
        <>
          {!text && !record?.fechahorafin ? (
            <></>
          ) : text && !record?.fechahorafin ? (
            <>{dayjs(text).format(DateFormats.DatetimeFrontEnd)}</>
          ) : (
            <>
              {dayjs(text).format(DateFormats.DatetimeFrontEnd)}{" "}
              <SwapRightOutlined
                style={{
                  fontSize: "16px",
                  color: "#4096ff",
                  fontWeight: "bold",
                }}
              />{" "}
              {dayjs(record?.fechahorafin).format(DateFormats.DatetimeFrontEnd)}
            </>
          )}
        </>
      ),
    },
    {
      title: "",
      dataIndex: "acciones",
      key: "acciones",
      render: (text, record) => (
        <Space size="middle">
          <ActionsDropdownUi
            menu={{
              items: itemsOptionsTable,
              onClick: (e) => handleMenuItemClick(e, record),
            }}
          />
        </Space>
      ),
    },
  ];
};
export const ListaPrecioTable = ({ listaprecios }) => {
  const dispatch = useDispatch();
  const handleEditar = (item) => {
    dispatch(listaprecioSetActive(item));
    dispatch(uiShowForm());
  };
  const handleEliminar = (item) => {
    showDeleteConfirm(
      "¿Está seguro de eliminar la lista de precios?",
      item.nombre,
      (isConfirmed) => {
        if (isConfirmed) {
          dispatch(listaprecioStartDelete(item));
        }
      }
    );
  };
  const handleMenuItemClick = (e, record) => {
    switch (e.key) {
      case "editar":
        handleEditar(record);
        break;
      case "eliminar":
        handleEliminar(record);
        break;
      default:
        break;
    }
  };
  return (
    <TableUi
      columns={columns({
        handleMenuItemClick,
      })}
      data={listaprecios}
      rowKey="id"
      size="small"
    />
  );
};
